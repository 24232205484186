import React from "react";
import "./ArtificialBlog.css"; // Import CSS file for styling

const ArtificialBlog = () => {
  return (
    <div className="artificial-intelligence-container">
      <h1 className="blog-title">
        Artificial Intelligence (AI): How Smart Computers are Changing Our World
      </h1>
      <div className="blog-content">
        <p>
          In today's world, computers are getting smarter thanks to a technology
          called Artificial Intelligence (AI). AI is like giving computers a
          brain so they can learn, think, and do tasks on their own.
        </p>
        <p>
          One cool thing about AI is how it's being used in self-driving cars.
          These cars use AI to "see" the road and make decisions, making driving
          safer and easier.
        </p>
        <p>
          AI is also being used in healthcare. Doctors can use AI to analyze
          lots of medical data quickly and accurately, helping them diagnose
          diseases faster and recommend better treatments.
        </p>
        <p>
          Another way AI is used is in personal assistants like Siri or Alexa.
          These assistants can understand what we say and help us with tasks
          like setting reminders or finding information online.
        </p>
        <p>
          AI is also helping in everyday tasks like shopping. Have you ever
          shopped online and seen suggestions for other products you might like?
          That's AI at work, learning your preferences and making
          recommendations.
        </p>
        <p>
          In the future, AI could help us in even more ways, like predicting
          natural disasters, managing traffic, or even discovering new
          medicines.
        </p>
        <p>
          Overall, AI is a powerful tool that is changing our world for the
          better, making things easier, safer, and more efficient.
        </p>
      </div>
    </div>
  );
};

export default ArtificialBlog;
