import React from "react";
import { Link } from "react-router-dom";

const NoPageFound = () => {
  return (
    <div className="container d-flex flex-column justify-content-center align-items-center vh-100">
      <div className="text-center">
        <h1 className="display-4">404 - Page Not Found</h1>
        <p className="lead">
          Oops! Looks like you've stumbled upon a page that doesn't exist.
        </p>
        <p>
          Don't worry, though. You can go back to{" "}
          <Link to="/" className="text-primary  ">
            Home Page
          </Link>{" "}
          or{" "}
          <Link to="/contact" className="text-primary">
            Contact Us
          </Link>{" "}
          for assistance.
        </p>
      </div>
    </div>
  );
};

export default NoPageFound;
