import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Screens/Home/Home";
import SearchModal from "../Screens/Search/SearchModal";
import AboutHeader from "../Screens/About/AboutHeader";
import ServiceHeader from "../Screens/Service/ServiceHeader";
import ContactHeader from "../Screens/contact/ContactHeader";
import FeatureHeader from "../Screens/features/FeatureHeasder";
import TeamHeader from "../Screens/team/TeamHeader";
import TestMonialHeader from "../Screens/TestMonial/TestMonialHeader";
import QuoteHeader from "../Screens/quote/QuoteHeader";
import BlogGridHeader from "../Screens/blog/BlogGridHeader";
import ArtificialHeader from "../Screens/blog/ArtificialBlog/ArtificialHeader";
import BlogChainHeader from "../Screens/blog/BlockChain/BlockChainHeader";
import InternetHeader from "../Screens/blog/ExploreInternet/InternetHeader";
import FiveGHeader from "../Screens/blog/Technology/FiveGHeader";
import QuantumHeader from "../Screens/blog/QuantumComputingGuide/QuantumHeader";
import FutureAiHeader from "../Screens/blog/FutureTechnology/FutureAiHeader";
import BlogDetails from "../Screens/blog/BlogDetails";
import NoPageFound from ".././Screens/NoPageFound/NoPageFound";
import Header from "../Screens/Header";
import Footer from "../components/Footer";
// import Portfolio from "../Screens/Portfolio/Portfolio";
import PortfolioHeader from "../Screens/Portfolio/PortfolioHeader";
import PrivacyPolicyHeader from "../Screens/PrivacyPolicy/PrivactPolicyHeader";
import CarrierHeader from "../Screens/Carrier/CarrierHeader";
import TestCaseOfProjects from "../Screens/testCases/TestCaseOfProjects";
const HeaderFooterRoutes = () => {
  return (
    <>
      <Header />
      {/* <Navbar /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search" element={<SearchModal />} />
        <Route path="/about" element={<AboutHeader />} />
        <Route path="/services" element={<ServiceHeader />} />
        <Route path="/contact" element={<ContactHeader />} />
        <Route path="/features" element={<FeatureHeader />} />
        <Route path="/team" element={<TeamHeader />} />
        <Route path="/testonsial" element={<TestMonialHeader />} />
        <Route path="/quote" element={<QuoteHeader />} />
        <Route path="/blogs" element={<BlogGridHeader />} />
        <Route path="/our-portfolio" element={<PortfolioHeader />} />
        <Route path="/careers" element={<CarrierHeader />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyHeader />} />
        <Route path="/blogs/:slug" element={<BlogDetails />} />
        <Route path="/artificial-intelligence" element={<ArtificialHeader />} />
        <Route path="/blockchain" element={<BlogChainHeader />} />
        <Route path="/internet-of-things" element={<InternetHeader />} />
        <Route path="/5g-technology" element={<FiveGHeader />} />
        <Route path="/quantum-computing" element={<QuantumHeader />} />
        {/* NoPageFound route is intentionally left out */}
        <Route path="/test-cases" element={<TestCaseOfProjects />} />
      </Routes>
      <Footer />
    </>
  );
};

const PageRoute = () => {
  return (
    <Routes>
      <Route path="*" element={<NoPageFound />} />
      {/* <Route path="/login" element={<Login />} /> */}

      <Route path="/" element={<HeaderFooterRoutes />}>
        <Route path="/search" element={<SearchModal />} />
        <Route path="/about" element={<AboutHeader />} />
        <Route path="/services" element={<ServiceHeader />} />
        <Route path="/contact" element={<ContactHeader />} />
        <Route path="/features" element={<FeatureHeader />} />
        <Route path="/team" element={<TeamHeader />} />
        <Route path="/testonsial" element={<TestMonialHeader />} />
        <Route path="/our-portfolio" element={<PortfolioHeader />} />
        <Route path="/careers" element={<CarrierHeader />} />
        <Route path="/quote" element={<QuoteHeader />} />
        <Route path="/blogs" element={<BlogGridHeader />} />
        <Route path="/blogs/:slug" element={<BlogDetails />} />
        <Route path="/artificial-intelligence" element={<ArtificialHeader />} />
        <Route path="/blockchain" element={<BlogChainHeader />} />
        <Route path="/internet-of-things" element={<InternetHeader />} />
        <Route path="/5g-technology" element={<FiveGHeader />} />
        <Route path="/quantum-computing" element={<QuantumHeader />} />
        <Route path="/artificial-future" element={<FutureAiHeader />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyHeader />} />
        <Route path="/test-cases" element={<TestCaseOfProjects />} />
      </Route>
    </Routes>
  );
};

export default PageRoute;
