import React from "react";
import "./QuantumBlog.css"; // Import CSS file for styling

const QuantumBlog = () => {
  return (
    <div className="quantum-container">
      <div className="quantum-header">
        <h1 className="quantum-title">
          Demystifying Quantum Computing: A Beginner's Guide
        </h1>
      </div>
      <div className="quantum-content">
        <section className="quantum-section">
          <h2 className="quantum-section-title">What is Quantum Computing?</h2>
          <p>
            Quantum computing is a type of computing that utilizes
            quantum-mechanical phenomena, such as superposition and
            entanglement, to perform operations on data. A qubit can be in a
            state of superposition, representing both 0 and 1 at the same time.
            This allows quantum computers to process a vast number of
            possibilities simultaneously, making them incredibly powerful for
            certain types of calculations.
          </p>
        </section>
        <section className="quantum-section">
          <h2 className="quantum-section-title">
            How Does Quantum Computing Work?
          </h2>
          <ul>
            <li>
              <strong>Superposition:</strong> A qubit can be in a state of
              superposition, representing both 0 and 1 at the same time. This
              allows quantum computers to process a vast number of possibilities
              simultaneously, making them incredibly powerful for certain types
              of calculations.
            </li>
            <li>
              <strong>Entanglement:</strong> Entanglement is a phenomenon where
              the state of one qubit is dependent on the state of another, even
              when they are separated by great distances. This property allows
              quantum computers to perform operations on multiple qubits
              simultaneously, enabling faster computation.
            </li>
            <li>
              <strong>Quantum Gates :</strong> Quantum gates are the building
              blocks of quantum circuits. They manipulate qubits to perform
              operations such as superposition, entanglement, and measurement,
              allowing quantum computers to solve complex problems
            </li>
          </ul>
        </section>
        <section className="quantum-section">
          <h2 className="quantum-section-title">
            Applications of Quantum Computing
          </h2>
          <ul>
            <li>
              <strong>Cryptography:</strong> Quantum computers have the
              potential to break current cryptographic systems, making them
              crucial for developing secure communication protocols for the
              future.
            </li>
            <li>
              <strong>Optimization:</strong> Quantum computers can solve complex
              optimization problems, such as finding the most efficient route
              for delivery trucks or optimizing financial portfolios, much
              faster than classical computers.
            </li>
            <li>
              <strong>Material Science:</strong> Quantum computers can simulate
              the behavior of molecules and materials, leading to advancements
              in drug discovery, material design, and other areas of research.
            </li>
            <li>
              <strong>Machine Learning:</strong> Quantum computing can enhance
              machine learning algorithms, enabling more accurate predictions
              and faster training of models.
            </li>
          </ul>
        </section>
        <section className="quantum-section">
          <h2 className="quantum-section-title">
            Challenges and Future Outlook
          </h2>
          <p>
            Despite its potential, quantum computing is still in its early
            stages, and several challenges remain, such as error rates and
            scalability.
          </p>
        </section>
      </div>
    </div>
  );
};

export default QuantumBlog;
