import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";

const Header = () => {
  return (
    <>
      <div className="container-fluid bg-dark d-none d-lg-block">
        <div className="row gx-0">
          <div className="d-flex text-center justify-content-between align-items-center text-lg-start mb-2 mb-lg-0">
            <div
            // className="d-inline-flex align-items-center"
            // style={{ height: "45px", cursor: "pointer" }}
            >
              <small className="me-3 text-light">
                <Link
                  className="text-light"
                  // to="https://www.google.com/maps/dir//Dice+Coder+3+Anand+bagh+near+mpeb+office+Dewas,+Madhya+Pradesh+455001/@22.9642324,76.0465879,14z/data=!4m5!4m4!1m0!1m2!1m1!1s0x396317fbd4270045:0x8650fe6259ac12f7"
                  to="https://maps.app.goo.gl/PWMJ9ENMETw4MhFE9"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-map-marker-alt me-2"></i>103, First Floor, Landmark apartment, Civil line, Mendki Road, Dewas (MP) 455001
                </Link>
              </small>
              <small className="me-3 text-light">
                <Link className="text-light" to="tel:+917223888881">
                  <i className="fa fa-phone-alt me-2"></i>+91 887-888-1368
                </Link>
              </small>
              {/* <small className="me-3 text-light">
                <Link className="text-light" to="tel:+917223888881">
                  <i className="fa fa-phone-alt me-2"></i>+91 722-388-8881
                </Link>
              </small> */}
              <small className="me-3 text-light">
                <Link className="text-light" to="tel:+917223888881">
                  <i className="fa fa-phone-alt me-2"></i>+91 727-235-4360
                </Link>
              </small>

              <small className="text-light">
                <Link className="text-light" to="mailto:info@xpertbytes.com">
                  <i className="fa fa-envelope-open me-2"></i>
                  info@xpertbytes.com
                </Link>
              </small>
            </div>
            <div className="text-center text-lg-end">
              <div
                className="d-inline-flex align-items-center"
                style={{ height: "45px" }}
              >
                <a
                  className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                  href="https://twitter.com/xpertbytes1368"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-twitter fw-normal"></i>
                </a>
                <a
                  className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                  href="https://www.facebook.com/people/XpertBytes-Pvt-Ltd/61558182244626/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook-f fw-normal"></i>
                </a>
                <a
                  className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                  href="https://www.linkedin.com/company/xpertbytes-pvt-ltd/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin-in fw-normal"></i>
                </a>
                <a
                  className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                  href="https://www.instagram.com/xpertbytes/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram fw-normal"></i>
                </a>
                {/* <a
              className="btn btn-sm btn-outline-light btn-sm-square rounded-circle"
              href="/"
            >
              <i className="fab fa-youtube fw-normal"></i>
            </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" ">
        <Navbar />
      </div>
    </>
  );
};

export default Header;
