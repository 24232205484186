import React from "react";
import blog4 from "../../../assets/img/blog-5.jpg"; // Import the image for each column
import { Link } from "react-router-dom";

const TruncatedContent = ({ content }) => {
  return (
    <p>{content.length > 300 ? `${content.substring(0, 200)}...` : content}</p>
  );
};

const FiveGColumn = () => {
  const content =
    "5G stands for 'fifth generation' and is the newest standard for wireless communications. It's like upgrading from a bicycle to a sports car in terms of speed and capabilities. With 5G, you can download movies in seconds, play high-quality video games without lag, and connect more devices to the internet at the same time.";

  return (
    <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
      <div className="blog-item bg-light rounded overflow-hidden">
        <div className="blog-img position-relative overflow-hidden">
          <Link to="/5g-technology">
            <img
              className="img-fluid"
              src={blog4}
              style={{ width: "100%", height: 255, objectFit: "cover" }}
              alt=""
            />
          </Link>
        </div>
        <div className="p-4">
          <h4 className="mb-3">The Simple Guide to 5G Technology</h4>
          <TruncatedContent content={content} />
          <Link className="text-uppercase" to="/5g-technology">
            Read More <i className="bi bi-arrow-right"></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FiveGColumn;
