import React from "react";
import dcVideo from "../../assets/img/dc-video.mp4";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
const HomeHeader = () => {
  return (
    <div>
      <div className="carousel-inner ">
        <div className="carousel-item active">
          <video autoPlay loop muted style={{ width: "100%" }}>
            <source src={dcVideo} type="video/mp4" />
          </video>
          <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
            <div className="p-3" style={{ maxWidth: "900px" }}>
              <div className="d-none d-lg-block">
                <h4
                  className="text-white text-uppercase mb-3 animated slideInDown"
                  style={{ fontFamily: "Lucida Sans Unicode" }}
                >
                  Unlocking Digital Excellence
                </h4>
              </div>
              <div className="d-lg-none">
                <div
                  className="text-white text-uppercase mb-3  animated slideInDown fs-6"
                  style={{ fontFamily: "Lucida Sans Unicode" }}
                >
                  Unlocking Digital Excellence
                </div>
              </div>
              <div className="d-none d-lg-block">
                <h4
                  className="display-1 text-white mb-md-4 animated zoomIn
              "
                  style={{ fontFamily: "Verdena" }}
                >
                  {/* <i> Innovative Solutions for Your Every Need</i>{" "} */}
                  <i>
                    Discover a new era of IT solutions that redefine what's
                    possible for your business.
                  </i>
                </h4>
              </div>
              <div className="d-lg-none mt-0 mb-3">
                <div
                  className="display-1 text-white  animated zoomIn fs-3
              "
                  style={{ fontFamily: "Verdena" }}
                >
                  <i>
                    {" "}
                    Discover a new era of IT solutions that redefine what's
                    possible for your business.
                  </i>{" "}
                </div>
              </div>
              <Link
                to="/quote"
                className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
              >
                Free Quote
              </Link>
              <Link
                to="/contact"
                className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                onClick={()=>{
                  ReactGA.event({
                    category:"Home",
                    action:"Click on Contact Us Button",
                    label:"Home Page",
                    
                  })
                  console.log("ashviniii")
                }}
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;
