import React, { useState } from "react";
import image1 from "../../assets/ArchdImage/archd-1.png";
import image2 from "../../assets/ArchdImage/archd-f-1.png";
import image3 from "../../assets/ArchdImage/archd-2.png";
import image4 from "../../assets/ArchdImage/archd-2.1.png";
import image5 from "../../assets/ArchdImage/archd-3.png";
import image6 from "../../assets/ArchdImage/archd-4.png";
import image7 from "../../assets/ArchdImage/archd-5.png";
import image8 from "../../assets/ArchdImage/archd-5.1.png";
import image9 from "../../assets/ArchdImage/archd-6.png";
import image10 from "../../assets/ArchdImage/archd-7.png";
import image11 from "../../assets/ArchdImage/archd-8.png";
import image12 from "../../assets/ArchdImage/archd-p-d-1.png";
import image13 from "../../assets/ArchdImage/archdP-1.png";
import image14 from "../../assets/ArchdImage/archdP-2.png";
import image15 from "../../assets/ArchdImage/archdPj-1.png";
import image16 from "../../assets/ArchdImage/archd-pj-3.png";
import image17 from "../../assets/ArchdImage/archd-pj-4.png";
import image18 from "../../assets/ArchdImage/archd-pj-5.jpg";
import image19 from "../../assets/ArchdImage/archd-pj-7.jpg";
import image20 from "../../assets/ArchdImage/archd-8.png";
import image21 from "../../assets/ArchdImage/archdW-1.png";
import image22 from "../../assets/ArchdImage/archdW-2.png";
import image23 from "../../assets/ArchdImage/archdC-1.png";
import image24 from "../../assets/ArchdImage/archdC-2.png";
import image25 from "../../assets/ArchdImage/archdC-5.png";
import image26 from "../../assets/ArchdImage/archdCC-1.png";
import image27 from "../../assets/ArchdImage/archdCQ-1.png";
import image28 from "../../assets/ArchdImage/archdQ-4.png";
import image29 from "../../assets/ArchdImage/archdQ-5.png";
import image30 from "../../assets/ArchdImage/archdI-1.png";
import image31 from "../../assets/ArchdImage/archdCa-2.png";
import image32 from "../../assets/ArchdImage/archdCa-3.png";
import image33 from "../../assets/ArchdImage/archdCo-1.png";
import image34 from "../../assets/ArchdImage/archdCo-2.png";
import image35 from "../../assets/ArchdImage/setting/archdS-1.png";
import image36 from "../../assets/ArchdImage/setting/archdS-2.png";
import image37 from "../../assets/ArchdImage/setting/archdS-4.png";
import image38 from "../../assets/ArchdImage/setting/archdS-5.png";
import image39 from "../../assets/ArchdImage/setting/archdS-6.png";
import image40 from "../../assets/ArchdImage/setting/archdS-7.png";
import image41 from "../../assets/ArchdImage/archdN-1.png";
import image42 from "../../assets/ArchdImage/archdS-1.png";
import SliderImage from "../../components/Slider";
import { Dialog } from "@mui/material";
import { IoClose } from "react-icons/io5";
import SliderPort from "../../components/SliderPort";
import { useNavigate } from "react-router-dom";
const ArchdPortfolio = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const images = [
    {
      id: 1,
      image: image1,
    },
    {
      id: 2,
      image: image2,
    },
    {
      id: 3,
      image: image3,
    },
    {
      id: 4,
      image: image4,
    },
    {
      id: 5,
      image: image5,
    },
    {
      id: 6,
      image: image6,
    },
    {
      id: 7,
      image: image7,
    },
    {
      id: 8,
      image: image8,
    },
    {
      id: 9,
      image: image9,
    },
    {
      id: 10,
      image: image10,
    },
    {
      id: 11,
      image: image11,
    },
    {
      id: 12,
      image: image12,
    },
    {
      id: 13,
      image: image13,
    },
    {
      id: 14,
      image: image14,
    },
    {
      id: 15,
      image: image15,
    },
    {
      id: 16,
      image: image16,
    },
    {
      id: 17,
      image: image17,
    },
    {
      id: 18,
      image: image18,
    },
    {
      id: 19,
      image: image19,
    },
    {
      id: 20,
      image: image20,
    },
    {
      id: 21,
      image: image21,
    },
    {
      id: 22,
      image: image22,
    },
    {
      id: 23,
      image: image23,
    },
    {
      id: 24,
      image: image24,
    },
    {
      id: 25,
      image: image25,
    },
    {
      id: 26,
      image: image26,
    },
    {
      id: 27,
      image: image27,
    },
    {
      id: 28,
      image: image28,
    },
    {
      id: 29,
      image: image29,
    },
    {
      id: 30,
      image: image30,
    },
    {
      id: 31,
      image: image31,
    },
    {
      id: 32,
      image: image32,
    },
    {
      id: 33,
      image: image33,
    },
    {
      id: 34,
      image: image34,
    },
    {
      id: 35,
      image: image35,
    },
    {
      id: 36,
      image: image36,
    },
    {
      id: 37,
      image: image37,
    },
    {
      id: 38,
      image: image38,
    },
    {
      id: 39,
      image: image39,
    },
    {
      id: 40,
      image: image40,
    },
    {
      id: 41,
      image: image41,
    },
    {
      id: 42,
      image: image42,
    },
  ];
  return (
    <div>
      <div
        className="container mt-4"
        style={{
          backgroundColor: "#F0F8FA",
          padding: "30px",
          boxShadow:
            "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
        }}
      >
        <div className="row">
          <div className="col-md-6">
            <h2 className="">Archd</h2>{" "}
            {/* <button onClick={() => navigate("/test-cases")}>Test-cases</button> */}
            <div className="d-flex mt-3 gap-5  shadow-lg p-4">
              <div className=" info-list-div d-flex flex-column gap-3 py-4">
                <div>Admin & Designer and Customer pannel</div>
                <div>Warehouse Integration</div>
                <div>Project Management</div> <div>Product Allocation</div>
                <div>Inventory Management</div>
                <div>Real-time Tracking</div>
                <div>Notifications and Alerts</div>{" "}
                <div>One to One chat feature</div>
              </div>{" "}
            </div>{" "}
          </div>
          <div className="col-md-6">
            {/* Second column with slider */}
            <SliderImage images={images} setOpen={setOpen} height={"475px"} />
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        // fullWidth
        onClose={() => setOpen(false)}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1200px",
            },
          },
        }}
      >
        <div className="p-4">
          <div className="d-flex align-items-center justify-content-between px-2">
            <h2
              className="text-center textFamily text-primary"
              style={{ fontSize: "28px" }}
            >
              Archd
            </h2>
            <div
              style={{
                fontSize: "25px",
                marginBottom: "10px",
                cursor: "pointer",
              }}
              onClick={() => setOpen(false)}
            >
              <IoClose />
            </div>
          </div>{" "}
          <div className="d-none d-sm-block">
            <SliderPort images={images} height={"630px"} />
          </div>
          <div style={{ width: "100%" }} className="d-sm-none">
            {images.map((el) => {
              return (
                <div>
                  <img
                    src={el?.image}
                    alt=""
                    style={{
                      height: "180px",
                      objectFit: "contain",
                      width: "100%",
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ArchdPortfolio;
