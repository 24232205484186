import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo1 from ".././assets/logo/filledImage.png";
import logo from ".././assets/logo/whiteImage.png";
import toast, { Toaster } from "react-hot-toast";
import { VscThreeBars } from "react-icons/vsc";
const Navbar = () => {
  const [showWhiteNavbar, setShowWhiteNavbar] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setShowWhiteNavbar(true);
      } else {
        setShowWhiteNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const logoToShow = showWhiteNavbar ? logo1 : logo;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setToken(null);
    toast.success("Admin LogOut Successfully");
    window.location.reload();
  };

  return (
    <div
      className={`container-fluid position-relative p-0 ${
        showWhiteNavbar ? "white-navbar" : ""
      }`}
    >
      <nav
        className={`navbar d-lg-flex navbar-expand-lg navbar-dark px-lg-5 py-lg-0 ${
          showWhiteNavbar ? "sticky-top navbar-dark" : ""
        }`}
      >
        <div className="d-none d-lg-block">
          <NavLink to="/" className="navbar-brand ">
            <img
              src={logoToShow}
              alt="Logo"
              // className="w-50"
              style={{ width: "300px", height: "auto" }}
            />
          </NavLink>
        </div>

        <div className="bg-dark d-flex justify-content-between align-items-center d-lg-none pb-2  pt-1 px-2">
          <div className="">
            <NavLink to="/" className="navbar-brand ">
              <img
                src={logoToShow}
                alt="Logo"
                className="w-50"
                // style={{ width: "160px", height: "auto" }}
              />
            </NavLink>
          </div>
          <div className="text-white " type="button" onClick={toggleMenu}>
            <VscThreeBars className="fs-2" />
          </div>
        </div>
        <div className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}>
          <div className="navbar-nav bg-sm-dark  ms-auto py-0">
            <NavLink
              exact
              to="/"
              className="nav-item nav-link"
              activeClassName="active"
              onClick={closeMenu}
            >
              Home
            </NavLink>
            <NavLink
              to="/about"
              className="nav-item nav-link"
              activeClassName="active"
              onClick={closeMenu}
            >
              About Us
            </NavLink>
            <NavLink
              to="/services"
              className="nav-item nav-link"
              activeClassName="active"
              onClick={closeMenu}
            >
              Services
            </NavLink>
            <NavLink
              to="/our-portfolio"
              className="nav-item nav-link"
              activeClassName="active"
              onClick={closeMenu}
            >
              Our Portfolio
            </NavLink>
            <NavLink
              to="/blogs"
              className="nav-item nav-link"
              activeClassName="active"
              onClick={closeMenu}
            >
              Blogs
            </NavLink>
            <NavLink
              to="/careers"
              className="nav-item nav-link"
              activeClassName="active"
              onClick={closeMenu}
            >
              Careers
            </NavLink>
            <NavLink
              to="/contact"
              className="nav-item nav-link"
              onClick={closeMenu}
            >
              Contact Us
            </NavLink>
            {token && (
              <Link className="nav-item nav-link" onClick={handleLogout}>
                Logout
              </Link>
            )}
          </div>
          {/* <button
            type="button"
            className="btn text-primary ms-3"
            data-bs-toggle="modal"
          data-bs-target="#searchModal"
            onClick={closeMenu}
          >0
            <i className="fa fa-search"></i>
          </button> */}
          <div className="d-none d-lg-block">
            <NavLink
              to="/services"
              className="btn btn-primary ms-sm-3  text-white "
              onClick={closeMenu}
            >
              Explore More
            </NavLink>
          </div>
        </div>
      </nav>

      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default Navbar;
