// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";

// const TruncatedContent = ({ content }) => {
//   return (
//     <p>{content.length > 100 ? `${content.substring(0, 160)}...` : content}</p>
//   );
// };

// const FutureAiColumn = () => {
//   const [blogs, setBlogs] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchBlogs = async () => {
//       try {
//         const response = await fetch("http://localhost:5000/get-Blog");
//         if (response.ok) {
//           const data = await response.json();
//           setBlogs(data.data);
//         } else {
//           console.error("Failed to fetch blogs");
//         }
//       } catch (error) {
//         console.error("Error fetching blogs:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchBlogs();
//   }, []);

//   return (
//     <>
//       {loading ? (
//         <span className="loader"></span>
//       ) : (
//         blogs.map((blog) => (
//           <div
//             key={blog._id}
//             className="col-lg-4 wow slideInUp"
//             data-wow-delay="0.3s"
//           >
//             <div className="blog-item bg-light rounded overflow-hidden">
//               <div className="blog-img position-relative overflow-hidden">
//                 <Link to={`/blogs/${blog.slug}`}>
//                   <img
//                     className="img-fluid"
//                     src={`http://localhost:5000/${blog.image}`}
//                     style={{ width: "100%", height: 255, objectFit: "cover" }}
//                     alt={blog.title}
//                   />
//                 </Link>
//               </div>
//               <div className="p-4">
//                 <h4 className="mb-3">{blog.title}</h4>
//                 <TruncatedContent content={blog.description} />
//                 <Link className="text-uppercase" to={`/blogs/${blog.slug}`}>
//                   Read More <i className="bi bi-arrow-right"></i>
//                 </Link>
//               </div>
//             </div>
//           </div>
//         ))
//       )}
//     </>
//   );
// };

// export default FutureAiColumn;
import React from "react";
import blog1 from "../../../assets/img/blog-1.jpg";
import { Link } from "react-router-dom";

const TruncatedContent = ({ content }) => {
  return (
    <p>{content.length > 100 ? `${content.substring(0, 160)}...` : content}</p>
  );
};

const FutureAiColumn = () => {
  const content =
    "In today's world, technology is changing fast and making our lives different in many ways. Let's take a look at some cool technologies that are going to change our future.";

  return (
    <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
      <div className="blog-item bg-light rounded overflow-hidden">
        <div className="blog-img position-relative overflow-hidden">
          <Link to="/artificial-future">
            <img
              className="img-fluid"
              style={{ width: "100%", height: 255, objectFit: "cover" }}
              src={blog1}
              alt=""
            />
          </Link>
        </div>
        <div className="p-4">
          <h4 className="mb-3">Future Technologies</h4>
          <TruncatedContent content={content} />
          <Link className="text-uppercase" to="/artificial-future">
            Read More <i className="bi bi-arrow-right"></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FutureAiColumn;
