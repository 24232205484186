import React from "react";
import "./InternetBlog.css"; // Import CSS file for styling

const InternetBlog = () => {
  return (
    <div className="iot-container">
      <div className="iot-header">
        <h1 className="iot-title">
          Exploring the Internet of Things (IoT) in Everyday Life
        </h1>
      </div>
      <div className="iot-content">
        <section className="iot-section">
          <h2 className="iot-section-title">What is IoT?</h2>
          <p>
            IoT is the concept of connecting any device to the internet and to
            other connected devices. This includes everything from smartphones,
            smartwatches, and home appliances to cars, streetlights, and
            industrial machines. These devices collect and share data, enabling
            them to be more efficient and intelligent in their operations.
          </p>
        </section>
        <section className="iot-section">
          <h2 className="iot-section-title">How Does IoT Work?</h2>
          <p>
            IoT devices are equipped with sensors that collect data, such as
            temperature, humidity, or location. This data is then sent to a
            central server or cloud platform for processing. Using this data,
            IoT devices can make decisions, take actions, or communicate with
            other devices, all without human intervention.
          </p>
        </section>
        <section className="iot-section">
          <h2 className="iot-section-title">
            Examples of IoT in Everyday Life
          </h2>
          <ul>
            <li>
              <strong>Smart Home:</strong> IoT devices like smart thermostats,
              lights, and security cameras can be controlled remotely via a
              smartphone. They can also learn from your habits and adjust
              settings to make your home more comfortable and secure.
            </li>
            <li>
              <strong>Wearable Devices:</strong> Fitness trackers and
              smartwatches are examples of IoT devices that monitor your health
              and activity levels. They can track your steps, heart rate, and
              even your sleep patterns, helping you stay healthy and active.
            </li>
            <li>
              <strong>Connected Cars:</strong> IoT technology is used in
              connected cars to provide real-time traffic information, monitor
              vehicle performance, and even enable autonomous driving features
              for a safer and more efficient driving experience.
            </li>
            <li>
              <strong>Smart Cities:</strong> IoT is transforming cities into
              smart cities by improving traffic management, reducing energy
              consumption, and enhancing public safety through connected devices
              like smart streetlights and traffic sensors.
            </li>
          </ul>
        </section>
        <section className="iot-section">
          <h2 className="iot-section-title">Benefits of IoT</h2>
          <ul>
            <li>
              <strong>Convenience:</strong> IoT devices make our lives easier by
              automating tasks and providing remote access to our devices.
            </li>
            <li>
              <strong>Efficiency:</strong> IoT improves efficiency by optimizing
              processes, reducing waste, and enabling predictive maintenance in
              industries.
            </li>
            <li>
              <strong>Safety and Security:</strong> IoT enhances safety and
              security by monitoring and controlling devices in real-time, such
              as home security systems and industrial equipment.
            </li>
          </ul>
        </section>
        <section className="iot-section">
          <h2 className="iot-section-title">Challenges and Considerations</h2>
          <ul>
            <li>
              <strong>Privacy:</strong> With IoT devices collecting and sharing
              data, privacy concerns arise regarding the security and ownership
              of this data.
            </li>
            <li>
              <strong>Interoperability:</strong> Ensuring that different IoT
              devices can communicate and work together seamlessly is a
              challenge that needs to be addressed.
            </li>
            <li>
              <strong>Security:</strong> As IoT devices become more
              interconnected, the risk of cyber attacks and data breaches
              increases, highlighting the need for robust security measures.
            </li>
          </ul>
        </section>
        <section className="iot-section">
          <h2 className="iot-section-title">Conclusion</h2>
          <p>
            In conclusion, IoT is transforming the way we live and work,
            offering numerous benefits and opportunities. However, it also comes
            with challenges that need to be addressed to fully realize its
            potential in creating a connected and intelligent world.
          </p>
        </section>
      </div>
    </div>
  );
};

export default InternetBlog;
