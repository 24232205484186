import React from "react";

import ArtificialColumn from "./ArtificialBlog/ArtificialColumn";
import BlockChainColumn from "./BlockChain/BlockChainColumn";
import InternetColumn from "./ExploreInternet/InternetColumn";
import FiveGColumn from "./Technology/FiveGColumn";
import QuantumColumn from "./QuantumComputingGuide/QuantumColumn";
import FutureAiColumn from "./FutureTechnology/FutureAiColumn";

const LatestBlog = () => {
  return (
    <div
      data-aos="fade-up"
      className="container-fluid py-sm-5 py-4 wow fadeInUp"
    >
      <div className="container py-sm-5 py-2">
        <div
          className="section-title text-center position-relative pb-3 mb-5 mx-auto"
          style={{ maxWidth: "600px" }}
        >
          <h5 className="fw-bold text-primary text-uppercase">Latest Blog</h5>
          <h1 className="mb-0">Read The Latest Articles from Our Blog Post</h1>
        </div>
        <div className="row g-5">
          <FutureAiColumn />

          <ArtificialColumn />
          <BlockChainColumn />
          <InternetColumn />
          <FiveGColumn />
          <QuantumColumn />
        </div>
      </div>
    </div>
  );
};

export default LatestBlog;
