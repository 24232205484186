import React from "react";
import featureImage from "../../assets/img/feature.jpg"; // Update the path as per your project structure

const Features = () => {
  return (
    <div
      className="container-fluid py-sm-5 py-2 wow fadeInUp"
      data-aos="fade-up"
    >
      <div className="container py-sm-5 py-4">
        <div
          className="section-title text-center position-relative pb-3 mb-5 mx-auto"
          style={{ maxWidth: "600px" }}
        >
          <h5 className="fw-bold text-primary text-uppercase">Why Choose Us</h5>
          <h1 className="mb-0">
            We Are Here to Grow Your Business Exponentially
          </h1>
        </div>
        <div className="row g-5">
          <div className="col-lg-4">
            <div className="row g-5">
              <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
                <div
                  className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-cubes text-white"></i>
                </div>
                <h4>Best In Industry</h4>
                <p className="mb-0">
                  Their offerings consistently meet or exceed high standards of
                  quality, reliability, and performance, leading to customer
                  trust and loyalty .They prioritize customer needs and deliver
                  exceptional experiences, earning a reputation for outstanding
                  service and support.
                </p>
              </div>
              <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
                <div
                  className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-award text-white"></i>
                </div>
                <h4>Award Winning</h4>
                <p className="mb-0">
                  For individuals and organizations alike, receiving an award
                  can be a source of motivation and inspiration to continue
                  striving for excellence. It reaffirms their commitment to
                  continuous improvement, innovation, and achieving even greater
                  success in the future
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 wow zoomIn"
            data-wow-delay="0.9s"
            style={{ minHeight: "350px" }}
          >
            <div className="position-relative h-100">
              <img
                className="position-absolute w-100 h-100 rounded wow zoomIn"
                data-aos="fade-up"
                src={featureImage}
                style={{ objectFit: "cover" }}
                alt="Feature"
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="row g-5">
              <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                <div
                  className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-users-cog text-white"></i>
                </div>
                <h4>Professional Staff</h4>
                <p className="mb-0">
                  The professional staff consists of highly skilled individuals
                  who possess deep knowledge and expertise in their respective
                  fields. They continuously seek opportunities for professional
                  development to stay ahead of industry trends and advancements
                </p>
              </div>
              <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                <div
                  className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-phone-alt text-white"></i>
                </div>
                <h4>24/7 Support</h4>
                <p className="mb-0">
                  Please feel free to reach out to us for a complimentary quote
                  on our services. Our team is eager to assist you and provide
                  customized solutions to meet your needs. Contact us today to
                  get started!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
