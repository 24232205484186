import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import testTonial1 from "../../assets/img/team-1.jpg";
import testTonial2 from "../../assets/img/team-2.jpg";
import testTonial3 from "../../assets/img/team-3.jpg";
import invertedIcon from "../../assets/inverted_icon.svg";
import starIcon from "../../assets/star_icon_1.svg";

const Testimonial = () => {
  const testimonials = [
    {
      name: "Abrina Yeh",
      image: testTonial1,
      content:
        "XpertBytes demonstrated exceptional expertise in React Native, enabling us to develop a top-notch cross-platform mobile application. Their profound knowledge of the framework was instrumental in surpassing our project goals. Our collaboration with XpertBytes on our React Native endeavor was highly fruitful. Their adeptness in the framework empowered us to create a mobile app that not only met but surpassed our envisioned outcome",
    },
    {
      name: "Aravind Aby",
      image: testTonial2,
      content:
        "We engaged XpertBytes for our iOS app development project, and we couldn't be happier with the results! Their team demonstrated exceptional skill and attention to detail throughout the development process. They were proactive in addressing any challenges that arose and consistently provided innovative solutions to enhance the user experience. We're thrilled with the final product and grateful for the expertise and dedication of the XpertBytes team!",
    },
    {
      name: "Smit Prakash",
      image: testTonial3,
      content:
        "Working with XpertBytes has been an absolute pleasure! Their expertise in PHP development helped us streamline our backend processes, resulting in significant efficiency gains for our company. The team's proficiency in the MERN stack was evident in the seamless integration of our front-end and back-end systems. They delivered our project on time and exceeded our expectations in terms of quality and functionality. Highly recommended!",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <section className="testimonial-section py-5">
      <div className="container">
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-card">
              <div className="row mx-lg-5">
                <div className="col-lg-5">
                  <img
                    className="img-responsive hero-class w-100"
                    src={testimonial.image}
                    alt={`testimonial-${index}`}
                  />
                </div>
                <div className="col-lg-7 px-md-4">
                  <img
                    className="img-responsive mb-4 mt-2"
                    style={{ width: "5rem" }}
                    src={invertedIcon}
                    alt="rating"
                  />
                  <div className="common-heading text-l">
                    <span>Client Testimonial</span>
                    <h2 className="mb-0">{testimonial.name}</h2>
                  </div>
                  <div className="t-text">
                    <p>{testimonial.content}</p>
                  </div>
                  <div className="client-thumbs mt-3">
                    <img
                      src={starIcon}
                      className="img-fluid"
                      style={{ width: "6rem" }}
                      alt="star"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonial;
