import React from "react";
// import "./testCase.css";
import {
  aboutData,
  clientChallenges,
  clientGoals,
  data,
  developmentNeeds,
  MainData,
  projects,
  sectionData,
  technologiesData,
} from "./test";
import { useNavigate } from "react-router-dom";
const TestCaseOfProjects = ({ download = false }) => {
  const navigate = useNavigate();
  return (
    <div>
      {" "}
      <section class=" ph-case-setnow-bnr pt-0 pb-0">
        <div class="container">
          <div
            class=" align-items-center "
            style={{ display: "flex", gap: 20 }}
          >
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <div class="ph-heading-title">
                <div
                  className=""
                  style={{ fontSize: "60px", color: "#d65152" }}
                >
                  {MainData?.section?.title}
                </div>
                {/* <img
                    alt="Logo"
                    src="https://protonshub-website.s3.us-east-2.amazonaws.com/4e3yrgml7vv76b0defs0gg7w3duj"
                  /> */}
                <h1>
                  <div class="text-white"> {MainData?.section?.status}</div>
                </h1>
                <div class="text-white" style={{ fontSize: "30px" }}>
                  <span style={{ color: "#d65152" }}>
                    {MainData?.section?.heading}
                  </span>
                  {MainData?.section?.subHeading}
                </div>
                <div
                  style={{
                    marginBottom: "50px",
                    color: "white",
                    marginTop: "10px",
                  }}
                >
                  Archd emerges as a powerful solution to enhance real estate
                  management and staging efficiency, providing users with
                  streamlined data handling and advanced features. With Archd,
                  users can manage multiple tenants effortlessly, switch between
                  them seamlessly, and remove image backgrounds with ease.
                </div>
              </div>

              {/* <div class="ph-case-store">
                  <h6 class="text-white">Download Now</h6>
                  <ul>
                    <li style={{ listStyleType: "none" }}>
                      <a href="javascript:;">
                        <img
                          alt="App store"
                          src="https://protonshub-website.s3.us-east-2.amazonaws.com/2tvr5slqtwp6o5nb0yhtis1mlrtx"
                        />
                      </a>
                    </li>
                    <li style={{ listStyleType: "none" }}>
                      <a href="javascript:;">
                        <img
                          alt="play store"
                          src="https://protonshub-website.s3.us-east-2.amazonaws.com/o1cwsshd402ybydq68tosu083kju"
                        />
                      </a>
                    </li>
                  </ul>
                </div> */}
              {/* {download && (
           
                )} */}
            </div>
            <div className="col-xxl-8 col-xl-10 col-lg-12 col-md-12 mx-auto">
              <div
                className="row justify-content-center"
                // style={{ paddingTop: "40px", paddingBottom: "40px" }}
              >
                <img
                  alt="Mobile App Developed by Protonshub Technologies"
                  src={MainData?.section?.image}
                  style={{ height: "600px", marginTop: "100px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Application Detail-> */}
      <section
        className="ph-industry-sec ph-case-aplication-icon"
        style={{ paddingBottom: "50px", paddingTop: "50px" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="ph-heading-title"
              style={{ marginBottom: "20px", textAlign: "center" }}
            >
              <h2>Application Details</h2>
            </div>
            <div className="col-xxl-10 col-xl-10 col-md-12 mx-auto">
              <div className="row">
                {data.slice(0, 4).map((item, index) => (
                  <div
                    className="col-xl-3 col-lg-3 col-md-6 col-sm-12"
                    key={index}
                  >
                    <div className="ph-Industries-box">
                      <img alt={item.description} src={item.image} />
                      <p>{item.description}</p>
                      <div className="ph-case-time-2nigt">
                        {item.locationImage ? (
                          <>
                            <img alt="Location" src={item.locationImage} />{" "}
                            {item.category}
                          </>
                        ) : item.details ? (
                          item.details
                        ) : (
                          item.category
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className="row">
                <div className="col-xxl-10 col-xl-10 col-md-12 mx-auto">
                  <div className="ph-bnr-2nightlogo justify-content-center text-center">
                    <img alt="SetNow" src={data[4].logo} />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- About project--> */}
      <section className="ph-setnow-about">
        <div className="container">
          <div className="row align-items-center">
            {aboutData?.map((section, index) => (
              <React.Fragment key={index}>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                  <div className="ph-heading-title">
                    <h2 className="text-white">
                      About
                      <span
                        style={{
                          fontSize: "60px",
                          color: "#d65152",
                          marginLeft: "10px",
                        }}
                      >
                        {section?.title}
                      </span>
                    </h2>
                    <p>
                      <span>
                        <b> {section?.title}</b>
                      </span>{" "}
                      <span className="text-white"> {section.text}</span>
                    </p>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                  <div className="ph-about-2night-img text-end">
                    <img
                      alt={section.imageDetail.alt}
                      src={section.imageDetail.src}
                    />
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </section>
      {/* <!-- Technology used--> */}
      <section className="ph-industry-sec ph-2night-technology ph-setnow-technology">
        <div className="container">
          <div className="row">
            <div
              className="ph-heading-title"
              style={{
                marginBottom: "40px",
                textAlign: "center",
                paddingTop: "40px",
              }}
            >
              <h2>Technologies Used</h2>
              <p className="col-xxl-8 col-xl-10 col-lg-12 col-md-12 mx-auto">
                Our dedicated team of app developers combines the strength of
                robust technologies with a well-planned strategy to develop this
                table & salon booking app.
              </p>
            </div>
            <div className="col-xxl-10 col-xl-10 col-md-12 mx-auto">
              <div className="row justify-content-center">
                {technologiesData.map((tech, index) => (
                  <div
                    className="col-xl-4 col-lg-4 col-md-4 col-sm-4"
                    key={index}
                  >
                    <div className="ph-Industries-box">
                      <img alt={tech.title} src={tech.image} />
                      <p>{tech.title}</p>
                      {tech.link ? (
                        <a
                          href={tech.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ph-case-time-2nigt"
                        >
                          {tech.description}
                        </a>
                      ) : (
                        <div className="ph-case-time-2nigt">
                          {tech.description}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- client needs for development --> */}
      <section className="ph-setnow-development ph-bg-color">
        <div className="container">
          <div
            className="row"
            style={{ paddingTop: "40px", paddingBottom: "40px" }}
          >
            {developmentNeeds.map((section, index) => (
              <React.Fragment key={index}>
                <div className="col-xl-6 col-lg-6 col-md-4 col-sm-6">
                  <div className="ph-2nigt-img-deve text-center">
                    <img alt={section.image.alt} src={section.image.src} />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-4 col-md-6 col-sm-6">
                  <div className="ph-2nigtwork">
                    <h2>{section.heading}</h2>
                    {section?.paragraphs?.map((paragraph, pIndex) => (
                      <p key={pIndex}>{paragraph}</p>
                    ))}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </section>
      {/* Client goal*/}
      <section className="ph-2nig-development">
        <div
          className="container"
          style={{ paddingTop: "40px", paddingBottom: "40px" }}
        >
          <div className="row justify-content-center align-items-center">
            {clientGoals?.map((section, index) => (
              <React.Fragment key={index}>
                <div className="col-xl-6 col-lg-4 col-md-6 col-sm-6">
                  <div className="ph-2nigtwork">
                    <h2>{section.heading}</h2>
                    {section.paragraphs.map((paragraph, pIndex) => (
                      <p key={pIndex}>{paragraph}</p>
                    ))}
                    <div className="ph-blog-ulli">
                      <ul>
                        {section.features.map((feature, fIndex) => (
                          <li key={fIndex}>{feature}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-4 col-sm-6">
                  <div className="ph-stage-dev text-center">
                    <img alt={section.image.alt} src={section.image.src} />
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </section>
      {/* <!-- client  challange --> */}
      <section className="ph-case-client-bnr ph-setnoww-clint-design pb-0 pt-0">
        <div className="container">
          {clientChallenges.map((section, index) => (
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7">
                <div className="ph-client-left">
                  <img alt="Client Challenges" src={section.image} />
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                <div className="">
                  <h2 className="" style={{ color: "#d65152" }}>
                    {section.heading}
                  </h2>
                  {section.paragraphs.map((paragraph, pIndex) => (
                    <p
                      key={pIndex}
                      className="text-white"
                      style={{ fontSize: "18px" }}
                    >
                      {" "}
                      {paragraph}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* <!-- end --> */}
      {/* <!-- solution --> */}
      <section className="">
        <div
          className="container"
          style={{ paddingTop: "40px", paddingBottom: "40px" }}
        >
          <div className="row">
            <div className="col-xxl-10 col-xl-10 col-lg-12 col-md-12 mx-auto">
              <div className="ph-heading-title">
                <h2 style={{ textAlign: "center", paddingBottom: "10px" }}>
                  {sectionData.section.heading}
                </h2>
                {sectionData.section.paragraphs.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </div>
            </div>
            <div className="col-xxl-8 col-xl-10 col-lg-12 col-md-12 mx-auto">
              <div
                className="row justify-content-center"
                style={{ paddingTop: "40px", paddingBottom: "40px" }}
              >
                <img
                  alt="Mobile App Developed by Protonshub Technologies"
                  src={sectionData.section.mainImage}
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          {/* <div className="d-flex">
            {sectionData.section.additionalImages.map((image, index) => (
              <div key={index} className="col-xl-6 col-lg-6 col-md-4 col-sm-6">
                <div className="">
                  <img
                    alt={`Additional image ${index + 1}`}
                    src={image}
                    className=""
                  />
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </section>
      {/* <!-- case study main start --> */}
      <section class="Ph-form-sec ph-bg-color">
        <div class="container">
          <div class="row">
            <div class="col-xxl-8 col-xl-10 col-md-12 mx-auto">
              <div class="ph-heading-title">
                <h2 className="" style={{ textAlign: "center" }}>
                  Read More Case Studies
                </h2>
                <p>
                  Our application software developers are industry-specific
                  technology experts with many years of combined experience
                  delivering interoperable, scalable, and flexible web & mobile
                  applications for all kinds of industries.
                </p>
              </div>
            </div>
            <div
              className="ph-OurTeam-content"
              style={{ paddingTop: "20px", paddingBottom: "30px" }}
            >
              <div className="row align-items-center">
                {projects?.map((project, index) => (
                  <div
                    className="col-xxl-4 col-xl-4 col-lg-4 col-md-6"
                    key={index}
                  >
                    <div className="ph-blog-box ph-portfolio-box">
                      <div className="ph-blog-img">
                        <img src={project.image} alt={project.title} />
                      </div>
                      <div className="ph-blog-text">
                        <a
                          href={"/our-portfolio"}
                          className="ph-blog-title"
                          style={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                          {project.title}
                        </a>
                        <p className="ph-work-headline">{project.headline}</p>
                        <p>
                          {project.description &&
                            `${project.description.substring(0, 100)}...`}
                          <div
                            onClick={() => navigate("/our-portfolio")}
                            style={{ textDecoration: "underline" }}
                          >
                            Read More
                            {/* {expanded[index] ? "Read Less" : "Read More"} */}
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TestCaseOfProjects;
