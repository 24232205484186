import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const SliderPort = ({ images, setOpen, height }) => {
  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow custom-prev-arrow" onClick={onClick}>
        <FaChevronLeft />
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow custom-next-arrow" onClick={onClick}>
        <FaChevronRight />
      </div>
    );
  };
  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    // speed: 1000,
    // arrows: true,
    prevArrow: <CustomPrevArrow />, // Use custom prevArrow component
    nextArrow: <CustomNextArrow />,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    // autoplaySpeed: 2000,
  };

  return (
    <Slider ref={sliderRef} {...settings}>
      {images.map((image, index) => (
        <div key={index}>
          <img
            src={image?.image}
            alt={`Slide ${index}`}
            style={{
              width: "100%",
              height: height,
              objectFit: "contain",
            }}
          />
        </div>
      ))}
    </Slider>
  );
};

export default SliderPort;
