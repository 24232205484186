import React from "react";
import { Link } from "react-router-dom";
import backgroundImage from "../../assets/img/carousel-1.jpg";
import TempPortfolio from "../tempPortfolio/TempPortFolio";

const PortfolioHeader = () => {
  return (
    <>
      <div
        className="container-fluid py-5"
        style={{
          background:
            "linear-gradient(rgba(9, 30, 62, 0.7), rgba(9, 30, 62, 0.7)), url(" +
            backgroundImage +
            ") center center no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="row py-5">
          <div className="col-12 pt-lg-5 mt-lg-5 text-center">
            <h1 className="display-4 text-white animated zoomIn">
              {" "}
              Our Portfolio
            </h1>
            <Link to="/" className="h5 text-white">
              Home
            </Link>
            <i className="far fa-circle text-white px-2"></i>
            <Link to="/our-portfolio" className="h5 text-white">
              Our Portfolio
            </Link>
          </div>
        </div>
      </div>
      {/* <PortfolioPage /> */}
      {/* <Portfolio /> */}
      <div className="w-100">
        <TempPortfolio />
        {/* <SliderPortfolio /> */}
      </div>
    </>
  );
};

export default PortfolioHeader;
