import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div className="container px-5" style={styles.container}>
      {/* <h4 className="text-center mb-4" style={styles.heading}>
        Privacy Policy
      </h4> */}
      <p style={styles.paragraph}>
        XpertBytes, operator of the website{" "}
        <Link to="http://www.xpertbytes.com" style={styles.link}>
          www.xpertbytes.com
        </Link>
        , is committed to protecting your privacy. By using our services, you
        agree to the terms outlined in this privacy policy.
      </p>
      <h3 style={styles.subHeading}>Information Collection and Use</h3>
      <p style={styles.paragraph}>
        We collect personally identifiable information such as your name, email,
        phone number, and website to provide you with the best service
        experience and for identification purposes.
      </p>
      <h3 style={styles.subHeading}>Log Data</h3>
      <p style={styles.paragraph}>
        We analyze Log Data including IP address, browser version, pages
        visited, and time spent on pages to improve user experience and create
        effective marketing strategies.
      </p>
      <h3 style={styles.subHeading}>Cookies</h3>
      <p style={styles.paragraph}>
        Our website uses cookies to enhance user experience. You can choose to
        accept, personalize, or refuse cookies.
      </p>
      <h3 style={styles.subHeading}>Third-Party Access</h3>
      <p style={styles.paragraph}>
        Third parties may access your Personal Information to perform tasks on
        our behalf. They are bound by strict nondisclosure agreements.
      </p>
      <h3 style={styles.subHeading}>Security</h3>
      <p style={styles.paragraph}>
        We use commercially acceptable means to protect your Personal
        Information, although absolute security cannot be guaranteed.
      </p>
      <h3 style={styles.subHeading}>Links to Other Sites</h3>
      <p style={styles.paragraph}>
        We advise you to review the privacy policies of external websites linked
        on our platform, as we have no control over their content or practices.
      </p>
      <h3 style={styles.subHeading}>Children’s Privacy</h3>
      <p style={styles.paragraph}>
        Our services are not intended for children under 15. Any information
        provided by children under this age will be promptly deleted.
      </p>
      <h3 style={styles.subHeading}>Changes to This Privacy Policy</h3>
      <p style={styles.paragraph}>
        We update our Privacy Policy regularly, and any changes become effective
        immediately upon posting on this page.
      </p>
      <h3 style={styles.subHeading}>Contact Us</h3>
      <p style={styles.paragraph}>
        If you have any questions or suggestions about our Privacy Policy,
        please{" "}
        <Link to="/contact" style={styles.link}>
          contact us
        </Link>
        .
      </p>
    </div>
  );
};

const styles = {
  container: {
    marginTop: "40px",
    borderRadius: "10px",
  },
  heading: {
    color: "#333",
  },
  subHeading: {
    color: "#555",
    marginTop: "20px",
  },
  paragraph: {
    color: "#666",
    lineHeight: "1.5",
  },
  link: {
    color: "#007bff",
    textDecoration: "none",
    // fontWeight: "bold",
  },
};

export default PrivacyPolicy;
