import archdImage from "./../../assets/ArchdImage/archdtest12.png";
import archdImage1 from "./../../assets/ArchdImage/archdtest5.png";
import archdImage2 from "./../../assets/ArchdImage/archdtest20.png";
import archdImage3 from "./../../assets/ArchdImage/archdtest14.png";
import mainImage from "./../../assets/ArchdImage/archdtest10.png";
import project1 from "./../../assets/ArchdImage/testCase21.png";
import project2 from "./../../assets/ArchdImage/testCase23.png";
import project3 from "./../../assets/ArchdImage/testCase24.png";

export const data = [
  {
    category: "Living Essentials and House stagings",
    image:
      "https://protonshub-website.s3.us-east-2.amazonaws.com/wsb5v8v26cv4d6rujy0cz4f7g6me",
    description: "Category",
  },
  {
    category: "USA",
    image:
      "https://protonshub-website.s3.us-east-2.amazonaws.com/65dz7kx3m3yq72l3dzprwf8tyzvr",
    description: "Client Location",
    // locationImage:
    //   "https://protonshub-website.s3.us-east-2.amazonaws.com/zfep2c15fjea00jtuh82k5aa1fdv",
  },
  {
    category: "5 months",
    image:
      "https://protonshub-website.s3.us-east-2.amazonaws.com/2renhgxzgrjasoisdbebgvtkuip6",
    description: "Development Time",
  },
  {
    category: "scrapping, Invetory management, Chat other",
    image:
      "https://media.istockphoto.com/id/1564718468/vector/asterisk-icon-vector.jpg?s=612x612&w=0&k=20&c=EBBNBzhfYT57AauM8KmTsaOlhg-5aViYUO7xv3Dn52Y=",
    description: "Features",
  },

  {
    logo: "https://protonshub-website.s3.us-east-2.amazonaws.com/4tq3qbumpkk32llo3o61uhtusnu7",
  },
];
export const technologiesData = [
  {
    title: "UI Design",
    image:
      "https://protonshub-website.s3.us-east-2.amazonaws.com/g7uoqbgi96ygdqh34r4ww5layt8f",
    description: "Figma",
  },
  {
    title: "Front End",
    image:
      "https://protonshub-website.s3.us-east-2.amazonaws.com/ptw2pagcnewzgtwsu0s73c2rt19b",
    description: "React Native",
    link: "https://www.protonshub.com/technologies/hire-react-native-mobile-app-developers",
  },
  {
    title: "Back End",
    image:
      "https://protonshub-website.s3.us-east-2.amazonaws.com/nnskftz9j50ih1j1nol1mt8zxrmi",
    description: "Node",
    link: "https://www.protonshub.com/technologies/node-js-development-company",
  },
];
export const aboutData = [
  {
    title: "Archd",
    image:
      "https://protonshub-website.s3.us-east-2.amazonaws.com/iyn23k53gzaswf80bz5f4g09a2q3",
    text: "The project aims to enhance the efficiency and functionality of a staging and real estate management platform. Key improvements include optimizing data scraping for faster performance, enabling users to manage multiple tenants with seamless switching, and incorporating advanced image background removal features. The platform will offer manual entry fields, allow product selection and RFID deletion, and automatically populate rental and sale prices based on categories. Integration with data feeds from Four Hands, Olliix, and World Market will be supported. A robust chat functionality will be implemented, supporting both text and multimedia messages. The dashboard will feature metrics similar to stageDNA and display an active project map. Additionally, market data from sources like Zillow, Redfin, and MLS will be updated weekly, providing comprehensive details on homes for sale, market trends, and agent information.",
    imageDetail: {
      src: archdImage,
      alt: "User-Friendly App Designed by Protonshub Technologies",
    },
  },
];
export const developmentNeeds = [
  {
    image: {
      alt: "Ticket Booking App Development Needs",
      src: archdImage1,
    },
    heading: "Client Needs for Development",
    paragraphs: [
      "The client needs for the development of this React and Node.js project are multifaceted. They require an enhanced data scraping mechanism to significantly boost speed and efficiency.The platform must support tenant management, allowing users to easily switch between multiple tenants",
      "A critical feature includes the ability to remove image backgrounds for better presentation. The client also wants to replace pre-populated options with a manual entry field and implement functionality to select products and delete RFIDs from Archd. Additionally, the system should automatically populate rental and sale prices based on category inputs and integrate data feeds from Four Hands, Olliix, and World Market. ",
      "The client also needs advanced image handling capabilities, including background removal for cleaner visuals. This feature enhances property presentation, making listings more attractive. Manual data entry, product selection, and RFID deletion from Archd provide users with greater control and flexibility in managing inventory.",
      "The client envisions a comprehensive dashboard with metrics and an active project map, similar to stageDNA but tailored to Archd. Real-time updates on active projects, market data, and performance indicators like average days on market are essential. Integration with vendor data feeds and chat functionality supporting text and multimedia will improve user communication and decision-making.",
    ],
  },
];
export const clientGoals = [
  {
    heading: "Client Goals",
    paragraphs: [
      `
Archd emerges as a powerful solution to enhance real estate management and staging efficiency, providing users with streamlined data handling and advanced features. With Archd, users can manage multiple tenants effortlessly, switch between them seamlessly, and remove image backgrounds with ease. Users can also avoid manual, repetitive tasks and have access to real-time market data and insightful metrics.`,
      "Here’s a list of features that we have targeted to build this all-in-one real estate management and staging app:",
    ],
    features: [
      "A signup page for easy user registration and login and Here’s a list of features that we have targeted to build this all-in-one real estate management and staging app:",
      "Optimized data scraping for faster and more efficient performance",
      "Manual data entry fields and product selection options and ntegration with data feeds from Four Hands, Olliix, and World Market",
      "Advanced information architecture and clear UI and Automatic population of rental and sale prices based on category",
      "Comprehensive dashboard metrics and active project map similar to stageDNA",
      "Chat functionality supporting text and multimedia message",
      "Secure authentication and authorization mechanisms",
      "Users can have multiple tenants and be able to switch tenants ",
    ],
    image: {
      alt: "Client App Development Goals",
      src: "https://protonshub-website.s3.us-east-2.amazonaws.com/b1bf0telnml86s3hinp5q9pu5hbh",
    },
  },
];
export const clientChallenges = [
  {
    image: archdImage2,
    heading: "Client Challenges",
    paragraphs: [
      "The main challenge that emerged while creating Set Now was merging the two domains while providing a smooth user experience. Our client wanted to reduce customers’ frustration due to the complexity of the existing booking processes, which affects their overall satisfaction.",
      "The client was very particular about the app’s navigation, design, and scalability. They wanted to develop a unified application that seamlessly combines salon service and table reservation functionalities, providing a one-stop solution for customers.",
      "Lastly, Set Now needed a robust backend system that could successfully optimize staff schedules based on real-time demand, ensuring efficient resource allocation for better productivity.",
    ],
  },
];
export const sectionData = {
  section: {
    heading: "Solutions Offered by Protonshub Technologies",
    paragraphs: [
      "At Protonshub Technologies, our team has collaborated closely with clients to develop the Archd app, addressing key challenges and delivering an advanced real estate management and staging solution. ",
      "We tackled the issue of slow data scraping by implementing optimized algorithms and efficient data handling processes, ensuring faster and more reliable performance. Additionally, we developed a robust system for managing multiple tenants, allowing seamless switching between them, and integrated advanced image processing tools for background removal.",
      "Our team made sure that the Set Now app was designed to meet the client’s brand identity and requirements. Not to mention, a dedicated customer support team was available 24/7 to assist with the client's queries or concerns.",
      "We ensured that the Archd app aligned with the client’s brand identity and specific requirements. A dedicated customer support team is available 24/7 to address any queries or concerns, providing ongoing assistance and ensuring a smooth user experience. ",
    ],
    mainImage: archdImage3,
    // additionalImages: [
    //   "https://t3.ftcdn.net/jpg/07/40/72/42/360_F_740724222_u2LMggRRiyEa5gGParvF1cemjfi0Fw3q.jpg",
    //   "https://t3.ftcdn.net/jpg/06/09/99/58/360_F_609995881_GRKeNSprQVqcPtbpRCmnYZ8jPoyG0yDn.jpg",
    // ],
  },
};
export const MainData = {
  section: {
    status: "Offline",
    heading: "Archd App ",
    subHeading: "with Visually Appealing Graphics",
    title: "ARCHD",
    image: mainImage,
  },
};
export const projects = [
  {
    image: project1,
    title: "OYA Rider",
    headline: "Food Delivery App",
    description:
      "OYA is a delivery app that enables consumers to receive their choice.food at their doorstep whenever they desire.",
    link: "digital-payment-app",
  },
  {
    image: project3,
    title: "Wolomi",
    headline: "Pregnancy community App",
    description:
      "Wolomi is the first online pregnancy community for women of color, led by a maternal health nurse of color.",
    link: "screen-casting-platform",
  },
  {
    image: project2,
    title: "Makro PRO",
    headline: "Shopping Management App",
    description:
      "With Makro PRO, you can say goodbye to the inconvenience of driving to the store ",
  },
];
