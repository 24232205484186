import React from "react";
import aboutImage from "../../assets/img/about.jpg"; // Update the path as per your project structure
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <div
      data-aos="fade-down"
      className="container-fluid py-sm-5 py-2 wow fadeInUp"
    >
      <div className="container py-sm-5 py-4">
        <div className="row g-5">
          <div className="col-lg-7">
            <div className="section-title position-relative pb-3 mb-5">
              <h5 className="fw-bold text-primary text-uppercase">About Us</h5>
              <h1 className="mb-0">
                The Best IT Solution With 10 Years of Experience
              </h1>
            </div>
            <p className="mb-4">
              Welcome to XpertBytes, where innovation meets expertise in the
              dynamic world of Information Technology! We are a passionate team
              of professionals dedicated to transforming ideas into digital
              reality.
            </p>
            <div className="row g-0 mb-3">
              <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                <h5 className="mb-3">
                  <i className="fa fa-check text-primary me-3"></i>Award Winning
                </h5>
                <h5 className="mb-3">
                  <i className="fa fa-check text-primary me-3"></i>Professional
                  Staff
                </h5>
              </div>
              <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                <h5 className="mb-3">
                  <i className="fa fa-check text-primary me-3"></i>24/7 Support
                </h5>
                <h5 className="mb-3">
                  <i className="fa fa-check text-primary me-3"></i>Happy Clients
                </h5>
              </div>
            </div>
            <div
              className="d-flex align-items-center mb-4 wow fadeIn"
              data-wow-delay="0.6s"
            >
              <div
                className="bg-primary d-flex align-items-center justify-content-center rounded"
                style={{ width: "60px", height: "60px" }}
              >
                <i className="fa fa-phone-alt text-white"></i>
              </div>
              <div className="ps-4">
                <h5 className="mb-2">Call to ask any question</h5>
                <h4 className="text-primary mb-0">+91 722-388-8881</h4>
              </div>
            </div>
            <Link
              to="/our-portfolio"
              className="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
              data-wow-delay="0.9s"
            >
              Explore Our Portfolio
            </Link>
          </div>
          <div className="col-lg-5" style={{ minHeight: "500px" }}>
            <div className="position-relative h-100">
              <img
                className="position-absolute w-100 h-100 rounded wow zoomIn"
                data-wow-delay="0.9s"
                src={aboutImage}
                style={{ objectFit: "cover" }}
                alt="About Us"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
