import React from "react";
import { SiReactos } from "react-icons/si";
import { FaWordpress } from "react-icons/fa6";
import { FaBusinessTime } from "react-icons/fa";
import { MdManageAccounts } from "react-icons/md";
import { Link } from "react-router-dom";
const Carrier = () => {
  const services = [
    {
      title: "WordPress Developer",
      description: "Experience : 1 to 3 year Vacancies : 1 Positions",
      icon: <FaWordpress />,
    },
    {
      title: "React Native Developer",
      description: "Experience : 1 to 3 year Vacancies : 1 Positions",
      icon: <SiReactos />,
    },
    {
      title: "Business Development Executive",
      description: "Experience : 1 to 2 year Vacancies : 2 Positions",
      icon: <FaBusinessTime />,
    },
    {
      title: "HR Manager",
      description: "Experience : 1 to 2 year Vacancies : 1 Positions ",
      icon: <MdManageAccounts />,
    },

    // Add more dummy services as needed
  ];
  return (
    <div>
      <div
        className="container-fluid py-sm-5 py-2 wow fadeInUp"
        data-aos="fade-up"
      >
        <div className="container  py-3">
          <div
            className="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="fw-bold text-primary text-uppercase">Careers</h5>
            <h1 className="mb-0">Why Join Our Team</h1>
            <h6>
              "Be part of our fast-growing team. Exciting opportunities await at
              <span className="fw-bold">
                {" "}
                Xpert <span className="text-primary">Bytes</span>
              </span>
              . We're seeking talented engineers and management graduates. Join
              us and shape the future of technology!"
            </h6>
          </div>
        </div>
      </div>
      <div className="bg-light py-5">
        <div className="container">
          <h1 className="text-center pb-4">
            Our Current
            <span className="text-primary"> Vacancies</span>
          </h1>
          <div className="row g-4">
            {services.map((service, index) => (
              <div
                key={index}
                className={`col-lg-3 col-md-6 wow zoomIn`}
                data-wow-delay={`${0.3 + index * 0.3}s`}
              >
                <div className="service-item rounded d-flex flex-column align-items-center justify-content-center text-center border border-primary">
                  <div
                    style={{
                      fontSize: "3em",
                      color: "#d65152",
                      marginBottom: "20px",
                    }}
                  >
                    {service.icon}
                  </div>

                  <h4 className="mb-3 " style={{ color: "#091e3e" }}>
                    {service.title}
                  </h4>
                  <p className="m-0" style={{ fontWeight: "90px" }}>
                    {service.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="text-center pt-sm-5 pt-3">
        <h1>Is your position not listed? Email us at</h1>
        <div className="text-center text-sm-center">
          <div className="bg-primary text-white d-inline-block px-3 rounded-pill py-1 gap-2">
            <span>
              <Link className="text-light" to="mailto:info@xpertbytes.com">
                <i className="fa fa-envelope-open me-2"></i>
                info@xpertbytes.com
              </Link>
            </span>
          </div>
        </div>
        <div className=" align-items-center  bg-primary "></div>
      </div>
    </div>
  );
};

export default Carrier;
