// import React, { useEffect, useState } from "react";
// import { FaPhoneAlt } from "react-icons/fa";
// // import ServiceDialogBox from "./ServiceDialogBox";

// const OurServices = () => {
//   const [services, setServices] = useState([]);
//   // const [token, setToken] = useState(localStorage.getItem("token"));
//   // console.log("token", token);
//   useEffect(() => {
//     fetch("http://localhost:5000/get-Service")
//       .then((response) => response.json())
//       .then((data) => setServices(data.data))
//       .catch((error) => console.error("Error fetching services:", error));
//   }, []);
//   return (
//     <div className="container-fluid py-5 wow fadeInUp" data-aos="fade-up">
//       <div className="container py-5">
//         <div
//           className="section-title text-center position-relative pb-3 mb-5 mx-auto"
//           style={{ maxWidth: "600px" }}
//         >
//           <h5 className="fw-bold text-primary text-uppercase">Our Services</h5>
//           <h1 className="mb-0">
//             Custom IT Solutions for Your Successful Business
//           </h1>
//         </div>
//         {/* {token && <ServiceDialogBox />} */}

//         <div className="row g-5">
//           {services
//             .filter((service) => service.status) // Filter out members with status false

//             .map((service, index) => (
//               <div
//                 key={index}
//                 className={`col-lg-4 col-md-6 wow zoomIn`}
//                 data-wow-delay={`${0.3 + index * 0.3}s`}
//               >
//                 <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
//                   <i
//                     className={`${service?.icon}`}
//                     style={{
//                       fontSize: "3em",
//                       color: "#d65152" /* Add any other styles you want */,
//                       marginBottom: "20px",
//                     }}
//                   ></i>
//                   <h4 className="mb-3 " style={{ color: "#091e3e" }}>
//                     {service?.title}
//                   </h4>
//                   <p className="m-0" style={{ fontWeight: "90px" }}>
//                     {service?.description}
//                   </p>
//                 </div>
//               </div>
//             ))}
//           <div
//             className="card text-center"
//             style={{ width: "18rem", backgroundColor: "#d65152" }}
//           >
//             <div className="card-body">
//               <h5 className="card-title">
//                 {" "}
//                 <FaPhoneAlt className="mt-4" size={40} />
//               </h5>
//               <h4 className="pt-3">Call Us For Quote</h4>
//               <p className="card-text text-white">
//                 Get in touch with us today for a free quote on our services
//               </p>
//               <h5 className="mb-3">+91 722-388-8881</h5>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default OurServices;

import React from "react";
import { FaPhoneAlt } from "react-icons/fa";

const OurServices = () => {
  const services = [
    {
      title: "Custom PHP Development Services",
      description:
        "Leverage the power of PHP for tailored solutions that meet your unique business needs.",
      icon: "fab fa-brands fa-php",
    },
    {
      title: "React.js Development Service",
      description:
        "Transform your web applications with React.js, delivering engaging user interfaces and seamless user experiences",
      icon: "fab fa-brands fa-react",
    },
    {
      title: "React Native App Development Service",
      description:
        "Extend your reach to mobile platforms with React Native, ensuring cross-platform compatibility and accelerated development",
      icon: "fab fa-brands fa-android",
    },
    {
      title: "Ios App Development Services",
      description:
        "Unlock the full potential of the iOS ecosystem with our comprehensive iOS app development services",
      icon: "fab fa-brands fa-apple",
    },
    {
      title: "Full-Stack Development Services",
      description:
        "Experience end-to-end development expertise with our full-stack services.",
      icon: "fa fa-solid fa-layer-group",
    },
    {
      title: "PHP and React.js Migration",
      description:
        "Upgrade your existing applications to modern technologies with our migration services.",
      icon: "fa fa-solid fa-database",
    },
    {
      title: "Custom API Development",
      description:
        "Facilitate seamless data exchange and integrations with our custom API development services.",
      icon: "fa fa-solid fa-mercury",
    },
    {
      title: "Maintenance and Support",
      description:
        "FEnsure the ongoing success of your applications with our reliable maintenance and support services.",
      icon: "fa fa-solid fa-question",
    },
    // Add more dummy services as needed
  ];

  return (
    <div
      className="container-fluid py-sm-5 py-2 wow fadeInUp"
      data-aos="fade-up"
    >
      <div className="container py-sm-5 py-4">
        <div
          className="section-title text-center position-relative pb-3 mb-5 mx-auto"
          style={{ maxWidth: "600px" }}
        >
          <h5 className="fw-bold text-primary text-uppercase">Our Services</h5>
          <h1 className="mb-0">
            Custom IT Solutions for Your Successful Business
          </h1>
        </div>

        <div className="row g-5">
          {services.map((service, index) => (
            <div
              key={index}
              className={`col-lg-4 col-md-6 wow zoomIn`}
              data-wow-delay={`${0.3 + index * 0.3}s`}
            >
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <i
                  className={`${service.icon}`}
                  style={{
                    fontSize: "3em",
                    color: "#d65152",
                    marginBottom: "20px",
                  }}
                ></i>
                <h4 className="mb-3 " style={{ color: "#091e3e" }}>
                  {service.title}
                </h4>
                <p className="m-0" style={{ fontWeight: "90px" }}>
                  {service.description}
                </p>
              </div>
            </div>
          ))}
          <div
            className="text-center px-2"
            style={{
              backgroundColor: "#d65152",
              marginLeft: "22px",
              width: "300px",
            }}
          >
            <div className="card-body">
              <h5 className="card-title">
                {" "}
                <FaPhoneAlt className="my-2" size={40} />
              </h5>
              <h4 className="">Call Us For Quote</h4>
              <p className="text-white">
                Get in touch with us today for a free quote on our services
              </p>
              <div className="fs-bold">
                <h6 className="">+91 903-949-0322</h6>
                {/* <h6 className="">+91 722-388-8881</h6> */}
                <h6 className="">+91 727-235-4360</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
