import React from "react";
import { Link } from "react-router-dom";
import blog3 from "../../../assets/img/blog-4.jpg";

const TruncatedContent = ({ content }) => {
  return (
    <p>{content.length > 200 ? `${content.substring(0, 202)}...` : content}</p>
  );
};

const InternetColumn = () => {
  const content =
    "The Internet of Things, or IoT, is all about connecting everyday objects to the internet, allowing them to send and receive data. This technology is changing the way we live, work, and interact with our surroundings.";

  return (
    <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
      <div className="blog-item bg-light rounded overflow-hidden">
        <div className="blog-img position-relative overflow-hidden">
          <Link to="/internet-of-things">
            <img
              className="img-fluid"
              src={blog3}
              style={{ width: "100%", height: 255, objectFit: "cover" }}
              alt=""
            />
          </Link>
        </div>
        <div className="p-4">
          <h4 className="mb-3">Exploring the Internet of Things</h4>
          <TruncatedContent content={content} />
          <Link className="text-uppercase" to="/internet-of-things">
            Read More <i className="bi bi-arrow-right"></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default InternetColumn;
