// import React, { useState, useEffect } from "react";
// import AddEmployee from "./AddEmployee";

// const TeamMembers = () => {
//   const [teamData, setTeamData] = useState([]);
//   const [token, setToken] = useState(localStorage.getItem("token"));

//   useEffect(() => {
//     fetch("http://localhost:5000/get-Employee")
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error("Failed to fetch data");
//         }
//         return response.json();
//       })
//       .then((data) => {
//         setTeamData(data.data);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error.message);
//       });
//   }, []);

//   return (
//     <>
//       <div className="container-fluid py-5 wow fadeInUp" data-aos="fade-up">
//         <div className="container py-5">
//           <div
//             className="section-title text-center position-relative pb-3 mb-5 mx-auto"
//             style={{ maxWidth: "600px" }}
//           >
//             <h5 className="fw-bold text-primary text-uppercase">
//               Team Members
//             </h5>
//             <h1 className="mb-0">
//               Professional Stuffs Ready to Help Your Business
//             </h1>
//           </div>
//           {token && <AddEmployee />}

//           <div className="row g-5 ">
//             {teamData
//               .filter((member) => member.status) // Filter out members with status false
//               .map((member, index) => (
//                 <div
//                   className="col-lg-4 wow slideInUp"
//                   key={index}
//                   data-wow-delay={`${0.3 + index * 0.3}s`}
//                 >
//                   <div className="team-item bg-light rounded overflow-hidden">
//                     <div className="team-img position-relative overflow-hidden">
//                       <img
//                         className="img-fluid w-100"
//                         src={`http://localhost:5000/${member.image}`}
//                         alt=""
//                       />
//                     </div>
//                     <div div className="text-center py-4">
//                       <h4 className="text-primary">{member.name}</h4>
//                       <p className="text-uppercase m-0">{member.designation}</p>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default TeamMembers;
import React from "react";
import team1 from "../../assets/img/Shatrudhan-choudhary.jpg";
import team2 from "../../assets/img/yogendra-sendhav.jpg";



import TeamMemberSlider from "./TeamMemberSlider";

const TeamMembers = () => {
  
  const slides = [
    {
      image: team2,
      post: "Founder/CEO",
      name: "Yogendra Sendhav",
      description:
        "As the CEO and owner of a leading software agency, I am dedicated to driving innovation and delivering top-notch digital solutions. With a keen focus on client satisfaction and a deep understanding of technology, I lead our team in creating cutting-edge software products that exceed expectations. My passion for excellence fuels our company's success, ensuring we remain at the forefront of the industry.",
      // post: "Founder/CEO",s
      experience: "Over 10+ years of experience",
      qualification: "Bachelor of Engineer",
    },
    {
      image: team1,
      post: "CO-Founder,Director",
      name: "Shatrudhan Choudhary",
      description:
        "As a co-founder of our software agency, I bring 10 years of experience in the tech industry, specializing in Web and App development . I am deeply passionate about leveraging technology to solve complex problems and drive innovation. My role involves overseeing the strategic direction of the company, fostering a culture of collaboration and excellence, and ensuring that we deliver high-quality solutions that exceed client expectations. I am committed to staying ahead of the curve in terms of industry trends and emerging technologies, and I thrive in dynamic and challenging environments where I can make a meaningful impact.",
      // post: "CO-Founder,Director",
      experience: "Over 10+ years of experience",
      qualification: "Bachelor of Engineer",
    },
  ];
  return (
    <>
      {/* <div data-aos="fade-up">
        <TeamMemberSlider slides={slides} />
      </div> */}
      <div
        className="container-fluid py-sm-5 py-3 wow fadeInUp"
        data-aos="fade-up"
      >
        {/* <div className="container py-5">
          <div
            className="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="fw-bold text-primary text-uppercase">
              Team Members
            </h5>
            <h1 className="mb-0">
              Professional Stuffs Ready to Help Your Business
            </h1>
          </div>
          {token && <AddEmployee />}

          <div className="row g-sm-5 g-2">
            {teamData.map((member, index) => (
              <div
                className="col-lg-4 wow slideInUp"
                key={index}
                data-wow-delay={`${0.3 + index * 0.3}`}
              >
                <div className="w-100 h-100 p-4">
                  <div
                    className="rounded-circle overflow-hidden"
                    style={{ height: "350px" }}
                  >
                    <img
                      src={member.image}
                      alt={`Slide ${index}`}
                      className="w-100 h-100 object-fit-cover"
                    />
                  </div>
                  <div div className="text-center py-4">
                    <h4 className="text-primary">{member.name}</h4>
                    <p className="text-uppercase m-0">{member.designation}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </>
  );
};

export default TeamMembers;
