import React from "react";
import blog1 from "../../../assets/img/blog-2.jpg";
import { Link } from "react-router-dom";

const TruncatedContent = ({ content }) => {
  return (
    <p>{content.length > 100 ? `${content.substring(0, 160)}...` : content}</p>
  );
};

const ArtificialColumn = () => {
  const content =
    "AI is also being used in healthcare. Doctors can use AI to analyze lots of medical data quickly and accurately, helping them diagnose diseases faster and recommend better treatments.";

  return (
    <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
      <div className="blog-item bg-light rounded overflow-hidden">
        <div className="blog-img position-relative overflow-hidden">
          <Link to="/artificial-intelligence">
            <img
              className="img-fluid"
              style={{ width: "100%", height: 255, objectFit: "cover" }}
              src={blog1}
              alt=""
            />
          </Link>
        </div>
        <div className="p-4">
          <h4 className="mb-3">Artificial Intelligence (AI)</h4>
          <TruncatedContent content={content} />
          <Link className="text-uppercase" to="/artificial-intelligence">
            Read More <i className="bi bi-arrow-right"></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ArtificialColumn;
