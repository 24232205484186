import React from "react";
import blog5 from "../../../assets/img/blog-6.jpg";
import { Link } from "react-router-dom";

const TruncatedContent = ({ content }) => {
  return (
    <p>{content.length > 250 ? `${content.substring(0, 190)}...` : content}</p>
  );
};

const QuantumColumn = () => {
  const content =
    "Quantum computing is an exciting and revolutionary technology that has the potential to solve complex problems at speeds far beyond what today's computers can achieve. In this guide, we'll explore the basics of quantum computing, how it works, and its potential applications.";

  return (
    <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
      <div className="blog-item bg-light rounded overflow-hidden">
        <div className="blog-img position-relative overflow-hidden">
          <Link to="/quantum-computing">
            <img
              className="img-fluid"
              src={blog5}
              style={{ width: "100%", height: 255, objectFit: "cover" }}
              alt="Quantum Computing"
            />
          </Link>
        </div>
        <div className="p-4">
          <h4 className="mb-3">Demystifying Quantum Computing</h4>
          <TruncatedContent content={content} />
          <Link className="text-uppercase" to="/quantum-computing">
            Read More <i className="bi bi-arrow-right"></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default QuantumColumn;
