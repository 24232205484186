import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import backgroundImage from "../../assets/img/carousel-2.jpg";
import { Fade } from "react-reveal";

const BlogDetails = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(`http://localhost:5000/blogs/${slug}`);
        if (response.ok) {
          const data = await response.json();
          setBlog(data?.data);
        } else {
          console.error("Failed to fetch blog post");
        }
      } catch (error) {
        console.error("Error occurred:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlog();
  }, [slug]);

  // Show loading message while fetching data
  // if (isLoading) {
  //   return <p>Loading...</p>;
  // }

  // Show message when no data is available
  if (!blog) {
    return <p>No Data Available</p>;
  }

  // Show blog details only if slug matches
  if (blog.slug !== slug) {
    return <p>No Data Available</p>;
  }

  return (
    <>
      {isLoading ? (
        <div
          id="spinner"
          className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
        >
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <div
            className="container-fluid py-5"
            style={{
              background:
                "linear-gradient(rgba(9, 30, 62, 0.7), rgba(9, 30, 62, 0.7)), url(" +
                backgroundImage +
                ") center center no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="row py-5">
              <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                <Fade>
                  <h1 className="display-4 text-white animated zoomIn">
                    {blog.title}
                  </h1>
                </Fade>
                <Fade delay={500}>
                  <Link to="/" className="h5 text-white">
                    Home
                  </Link>
                  <i className="far fa-circle text-white px-2"></i>
                  <Link to={`/blogs/${blog.slug}`} className="h5 text-white">
                    {blog.title}
                  </Link>
                </Fade>
              </div>
            </div>
          </div>
          <div className="container container-fluid mt-5">
            <div className="row justify-content-center">
              <div>
                <Fade bottom>
                  <div>
                    <div className="blog-title fw-bold text-primary ">
                      <h2>{blog.title}</h2>
                    </div>
                    <div>
                      {blog.description.split("\n")?.map((paragraph, index) => (
                        <React.Fragment key={index}>
                          <p className="blog-content">{paragraph}</p>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BlogDetails;
