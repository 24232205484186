import React, { useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const Contact = () => {
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Create a new FormData object
    const formData = new FormData();
    for (const key in userData) {
      if (userData.hasOwnProperty(key)) {
        formData.append(key, userData[key]);
      }
    }
    try {
      const response = await axios.post(
        `https://dicecoder.com/send-mail/mail.php`,
        formData
      );
      console.log("Contact form submitted:", response?.data?.success);
      // Reset form data after successful submission
      if (response?.data?.success) {
        toast.success("Message send successfully!");
        setUserData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      } else {
        toast.error(response?.data?.message || "Something went wrong!");
      }
    } catch (error) {
      toast.error("Failed to send message. Please try again later.");
    }
  };

  return (
    <div
      data-aos="fade-up"
      className="container-fluid py-sm-5 py-2 wow fadeInUp"
    >
      <div className="container py-sm-5 py-4">
        <div
          className="section-title text-center position-relative pb-3 mb-5 mx-auto"
          style={{ maxWidth: "600px" }}
        >
          <h5 className="fw-bold text-primary text-uppercase">Contact Us</h5>
          <h1 className="mb-0">
            If You Have Any Query, Feel Free To Contact Us
          </h1>
        </div>
        <div className="row g-5 mb-5">
          <div className="col-lg-4">
            <div
              className="d-flex align-items-start wow fadeIn"
              data-aos="fade-up"
            >
              <div
                className="bg-primary d-flex align-items-center justify-content-center rounded mt-1"
                style={{ width: "60px", height: "60px" }}
              >
                <i className="fa fa-phone-alt text-white"></i>
              </div>
              <div className="ps-4">
                <h5 className="mb-2">Call to ask any question</h5>
                <h4 className="text-primary mb-0 mt-1">+91 887-888-1368</h4>
                {/* <h4 className="text-primary mb-0">+91 722-388-8881</h4> */}
                <h4 className="text-primary mb-0">+91 727-235-4360</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div
              className="d-flex align-items-start wow fadeIn"
              data-wow-delay="0.4s"
            >
              <div
                className="bg-primary d-flex align-items-center justify-content-center rounded mt-1"
                style={{ width: "60px", height: "60px" }}
              >
                <i className="fa fa-envelope-open text-white"></i>
              </div>
              <div className="ps-4">
                <h5 className="mb-2">Email to get free quote</h5>
                <h4 className="text-primary mb-0">info@xpertbytes.com</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div
              className="d-flex align-items-start wow fadeIn"
              data-wow-delay="0.8s"
            >
              <div
                className="bg-primary d-flex align-items-center justify-content-center rounded px-4 mt-1"
                style={{ width: "60px", height: "60px" }}
              >
                <i className="fa fa-map-marker-alt text-white"></i>
              </div>
              <div className="ps-4">
                <h5 className="mb-2">Visit our office</h5>
                <h4 className="text-primary mb-0">
                103, First Floor, Landmark apartment, Civil line, Mendki Road, Dewas (MP) 455001
                </h4>
              </div>
            </div>
          </div>
        </div>
        {/* Form and Map */}
        <div className="row">
          <div className="col-md-6">
            <form onSubmit={handleSubmit}>
              <div className="row g-3 ">
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control border-0 bg-light px-4"
                    placeholder="Your Name"
                    style={{ height: "55px" }}
                    name="name"
                    value={userData?.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="email"
                    className="form-control border-0 bg-light px-4"
                    placeholder="Your Email"
                    style={{ height: "55px" }}
                    name="email"
                    value={userData?.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control border-0 bg-light px-4"
                    placeholder="Subject"
                    style={{ height: "55px" }}
                    name="subject"
                    value={userData.subject}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-12">
                  <textarea
                    className="form-control border-0 bg-light px-4 py-3"
                    rows="4"
                    placeholder="Message"
                    name="message"
                    value={userData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <div className="col-12">
                  <button className="btn btn-primary w-100 py-3" type="submit">
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-6 mt-5 mt-sm-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.606619339714!2d76.04184611117782!3d22.964727979127623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3963179c7d080971%3A0x4ed1409fea260bd4!2sLandMark%20Apartment%2C%20Mendki%20Rd%2C%20LIG%20Colony%2C%20Pachunkar%20Colony%2C%20Ram%20Nagar%2C%20Dewas%2C%20Madhya%20Pradesh%20455001!5e1!3m2!1sen!2sin!4v1726576366976!5m2!1sen!2sin"
              // src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14694.478416269345!2d76.0465879!3d22.9642324!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396317fbd4270045%3A0x8650fe6259ac12f7!2sDice%20Coder!5e0!3m2!1sen!2sin!4v1709110350023!5m2!1sen!2sin"
              style={{ border: "0", width: "100%", minHeight: "400px" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="#"
            ></iframe>
            
          </div>
        </div>
        {/* <div className="row g-5 mb-5">
          <div className="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
           
          </div>
          <div className="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
         
          </div>
        </div> */}
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default Contact;
