import React from "react";
import "./FiveGTechnolgy.css"; // Import CSS file for styling

const FiveGTechnology = () => {
  return (
    <div className="fiveg-container">
      <div className="fiveg-header">
        <h1 className="fiveg-title">The Simple Guide to 5G Technology</h1>
      </div>
      <div className="fiveg-content">
        <section className="fiveg-section">
          <h2 className="fiveg-section-title">What is 5G?</h2>
          <p>
            5G stands for "fifth generation" and is the newest standard for
            wireless communications. It promises faster speeds, lower latency,
            and more reliable connections than ever before.
          </p>
        </section>
        <section className="fiveg-section">
          <h2 className="fiveg-section-title">How Does 5G Work?</h2>
          <p>
            5G uses higher radio frequencies than previous generations, allowing
            for faster data transmission. However, these higher frequencies
            don't travel as far, so 5G networks require more antennas placed
            closer together to provide coverage.
          </p>
        </section>
        <section className="fiveg-section">
          <h2 className="fiveg-section-title">Benefits of 5G</h2>
          <ul>
            <li>
              Faster Speeds: Up to 10 gigabits per second download speeds.
            </li>
            <li>
              Lower Latency: Smoother and more responsive activities like gaming
              and video calling.
            </li>
            <li>
              More Devices Connected: Handling more connected devices
              simultaneously.
            </li>
          </ul>
        </section>
        <section className="fiveg-section">
          <h2 className="fiveg-section-title">Real-World Applications</h2>
          <ul>
            <li>
              Smartphones: Faster download speeds and better connectivity.
            </li>
            <li>
              Internet of Things (IoT): More efficient communication for smart
              devices.
            </li>
            <li>
              Healthcare: Enable remote surgeries and real-time patient
              monitoring.
            </li>
          </ul>
        </section>
        <section className="fiveg-section">
          <h2 className="fiveg-section-title">Challenges and Future Outlook</h2>
          <p>
            While 5G offers many benefits, challenges such as infrastructure
            needs and privacy concerns must be addressed. However, as 5G
            continues to roll out worldwide, it has the potential to transform
            industries and change the way we live, work, and communicate.
          </p>
        </section>
      </div>
    </div>
  );
};

export default FiveGTechnology;
