import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const TeamMemberSlider = ({ slides }) => {
  const sliderRef = useRef(null);
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div className="container px-sm-5 my-4">
      <Slider ref={sliderRef} {...settings}>
        {slides?.map((slide, index) => (
          <div key={index} className="p-sm-2 p-1">
            <div className="row">
              <div className="col-md-6 d-flex justify-content-center align-items-center">
                {/* Image Column */}
                <div
                  className="rounded-circle overflow-hidden"
                  style={{ width: "300px", height: "300px" }}
                >
                  <img
                    src={slide.image}
                    alt={`Slide ${index}`}
                    className="w-100 h-100 object-fit-cover"
                  />
                </div>
              </div>
              <div className="col-md-6 text-start py-4 py-sm-4">
                <div className="text-center text-sm-start">
                  <div className="bg-primary text-white d-inline-block px-3 rounded-pill text-uppercase ">
                    {slide.post}
                  </div>
                </div>
                <div className="border-bottom border-1">
                  <h2 className=" text-capitalize my-3">{slide.name}</h2>
                  <p className="text-muted">{slide.description}</p>
                </div>
                <div className="mt-3">
                  <div>
                    <span
                      className="fs-7 text-primary text-uppercase"
                      style={{ fontSize: "14px" }}
                    >
                      Post
                    </span>
                    <p className="" style={{ fontSize: "14px" }}>
                      {slide.post}
                    </p>
                  </div>
                  <div>
                    <span
                      className=" text-primary text-uppercase"
                      style={{ fontSize: "14px" }}
                    >
                      Experiece
                    </span>
                    <p className="" style={{ fontSize: "14px" }}>
                      {slide.experience}
                    </p>
                  </div>
                  <div>
                    <span
                      className=" text-primary text-uppercase"
                      style={{ fontSize: "14px" }}
                    >
                      Qualification
                    </span>
                    <p className="" style={{ fontSize: "14px" }}>
                      {slide.qualification}
                    </p>
                  </div>
                </div>
                <div className="d-flex mt-4">
                  <Link
                    className="btn btn-primary btn-square me-2"
                    to="https://twitter.com/xpertbytes1368"
                    target="_blank"
                  >
                    <i className="fab fa-twitter fw-normal"></i>
                  </Link>
                  <Link
                    className="btn btn-primary btn-square me-2"
                    to="https://www.facebook.com/people/XpertBytes-Pvt-Ltd/61558182244626/"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-f fw-normal"></i>
                  </Link>
                  <Link
                    className="btn btn-primary btn-square me-2"
                    to="https://www.linkedin.com/company/xpertbytes-pvt-ltd/"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin-in fw-normal"></i>
                  </Link>
                  <Link
                    className="btn btn-primary btn-square"
                    to="https://www.instagram.com/xpertbytes/"
                    target="_blank"
                  >
                    <i className="fab fa-instagram fw-normal"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TeamMemberSlider;
