import React, { useEffect } from "react";
// import logo from ".././assets/Vector-2-removebg-preview.png";
import logo from ".././assets/logo/whiteImage.png";
import { Link, NavLink, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <div
        className="container-fluid bg-dark text-light mt-5 wow fadeInUp"
        // data-aos="fade-up"
      >
        <div className="container">
          <div className="row gx-5">
            <div className="col-lg-4 col-md-6 footer-about">
              <div className="d-flex flex-column align-items-start justify-content-start text-start ">
                <div className="navbar-brand mt-5">
                  <NavLink to="/">
                    {/* <h1 className="text-light">
                        {" "}
                        Xpert<span className="text-dark">Bytes</span>
                      </h1> */}
                    <img
                      src={logo}
                      alt="Logo"
                      className="w-75"
                      // style={{ width: "300px", height: "auto" }}
                    />
                  </NavLink>
                  {/* <h1 className="m-0 text-white d-flex align-items-center">
                      <img
                        src={logo}
                        alt="Startup Logo"
                        className="me-2"
                        style={{
                          width: "250px",
                          height: "60px",
                          // backgroundColor: "grey",
                          // borderRadius: "5%",
                        }}
                      />
                    </h1> */}
                </div>

                <p className="text-start" style={{ paddingRight: "20px" }}>
                  At XpertBytes, we pride ourselves on being more than just a
                  technology company. We are your partners in progress,
                  committed to delivering cutting-edge solutions that drive your
                  business forward.
                </p>
                {/* <form>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control border-white p-3"
                      placeholder="Your Email"
                    />
                    <button className="btn btn-dark">Sign Up</button>
                  </div>
                </form> */}
              </div>
            </div>
            <div className="col-lg-8 col-md-6">
              <div className="row gx-5">
                <div className="col-lg-4 col-md-12 pt-sm-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Get In Touch</h3>
                  </div>

                  <div className="d-flex mb-2">
                    <i className="bi bi-geo-alt text-primary me-2"></i>
                    <p className="mb-0">
                    103, First Floor, Landmark apartment, Civil line, Mendki Road, Dewas (MP) 455001
                    </p>
                  </div>
                  <div className="">
                    <Link
                      to="mailto:info@xpertbytes.com"
                      className="d-flex mb-2"
                    >
                      <i className="bi bi-envelope-open text-primary me-2"></i>
                      <p className="mb-0 text-white">info@xpertbytes.com</p>
                    </Link>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-telephone text-primary me-2"></i>
                    <p className="mb-0">+91 887-888-1368</p>
                  </div>
                  {/* <div className="d-flex mb-2">
                    <i className="bi bi-telephone text-primary me-2"></i>
                    <p className="mb-0">+ 91 722-388-8881</p>
                  </div> */}
                  <div className="d-flex mb-2">
                    <i className="bi bi-telephone text-primary me-2"></i>
                    <p className="mb-0">+91 727-235-4360</p>
                  </div>
                  <div className="d-flex mt-4">
                    <Link
                      className="btn btn-primary btn-square me-2"
                      to="https://twitter.com/xpertbytes1368"
                      target="_blank"
                    >
                      <i className="fab fa-twitter fw-normal"></i>
                    </Link>
                    <Link
                      className="btn btn-primary btn-square me-2"
                      to="https://www.facebook.com/people/XpertBytes-Pvt-Ltd/61558182244626/"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-f fw-normal"></i>
                    </Link>
                    <Link
                      className="btn btn-primary btn-square me-2"
                      to="https://www.linkedin.com/company/xpertbytes-pvt-ltd/"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin-in fw-normal"></i>
                    </Link>
                    <Link
                      className="btn btn-primary btn-square"
                      to="https://www.instagram.com/xpertbytes/"
                      target="_blank"
                    >
                      <i className="fab fa-instagram fw-normal"></i>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Quick Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    <Link className="text-light mb-2" to="/">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Home
                    </Link>
                    <Link className="text-light mb-2" to="/about">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      About Us
                    </Link>
                    <Link className="text-light mb-2" to="/services">
                      <i className="bi bi-arrow-right text-primary me-2"></i>Our
                      Services
                    </Link>
                   
                    <Link className="text-light mb-2" to="/blogs">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Latest Blog
                    </Link>
                    <Link className="text-light mb-2" to="/contact">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Contact Us
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Popular Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    <Link className="text-light mb-2" to="/">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Home
                    </Link>
                    <Link className="text-light mb-2" to="/about">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      About Us
                    </Link>
                    <Link className="text-light mb-2" to="/contact">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Join Us{" "}
                    </Link>
                    <Link className="text-light mb-2" to="/services">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      What We Do
                    </Link>
                    <Link className="text-light mb-2" to="/5g-technology">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      The Simple Guide to 5g...
                    </Link>
                    <Link className="text-light mb-2" to="/artificial-future">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Exciting World of Future...
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid text-white"
        style={{ background: "#061429" }}
      >
        <div className="container text-center">
          <div className="row justify-content-end">
            <div className="">
              <div
                className="d-flex gap-2 align-items-center justify-content-center px-sm-3"
                style={{ height: "75px" }}
              >
                <div className="mb-0">
                  &copy;
                  <Link className="text-white border-bottom" to="#">
                    Copyright 2021 XpertBytes
                  </Link>
                  , All Rights Reserved
                </div>
                <div className="border-bottom">
                  <Link className="text-light" to="/privacy-policy">
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
