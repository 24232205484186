import React from "react";
import { Link } from "react-router-dom";
import "./FutureTechnology.css"; // Import CSS file for styling

const FutureAiBlog = () => {
  return (
    <div className="future-tech-container">
      <h1 className="future-tech-title">
        The Exciting World of Future Technologies: What's Coming Next?
      </h1>
      <div className="future-tech-list">
        <div className="future-tech-item">
          <Link
            to="/artificial-intelligence"
            className="future-tech-link"
            title="Artificial Intelligence"
          >
            Artificial Intelligence (AI)
          </Link>
          : AI is like a smart computer that can learn and think on its own.
          It's used in things like self-driving cars and personalized medicine,
          making them better and safer.
        </div>
        <div className="future-tech-item">
          <Link
            to="/blockchain"
            className="future-tech-link"
            title="Blockchain"
          >
            Blockchain
          </Link>
          : Blockchain is a special way of keeping information safe and private.
          It's not just for money, but can also help in tracking things like
          food and voting.
        </div>
        <div className="future-tech-item">
          <Link
            to="/internet-of-things"
            className="future-tech-link"
            title="Internet of Things"
          >
            Internet of Things (IoT)
          </Link>
          : IoT connects everyday things like your fridge or car to the
          internet. It makes life easier and can help save energy and time.
        </div>
        <div className="future-tech-item">
          <Link
            to="/5g-technology"
            className="future-tech-link"
            title="5G Technology"
          >
            5G Technology
          </Link>
          : 5G is a new kind of internet that's super fast and can connect many
          devices at once. It will make things like remote surgery and virtual
          reality even better.
        </div>
        <div className="future-tech-item">
          <Link
            to="/quantum-computing"
            className="future-tech-link"
            title="Quantum Computing"
          >
            Quantum Computing
          </Link>
          : Quantum computing is a new way of doing super-fast calculations.
          It's still new, but it can solve big problems in areas like science
          and security.
        </div>
      </div>
    </div>
  );
};

export default FutureAiBlog;
