import React, { useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const RequestQuote = () => {
  const [userData, setFormData] = useState({
    name: "",
    email: "",
    service: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in userData) {
      if (userData.hasOwnProperty(key)) {
        formData.append(key, userData[key]);
      }
    }
    try {
      const response = await axios.post(
        "https://dicecoder.com/send-mail/mail.php",
        formData
      );
      // Reset form data
      setFormData({
        name: "",
        email: "",
        service: "",
        message: "",
      });
      if (response.status === true) {
        toast.success("Message Sent Succesfully");
      }
    } catch (error) {
      console.error("Error submitting quote request:", error);
    }
  };

  return (
    <div className="container-fluid py-5 wow fadeInUp" data-aos="fade-up">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-7">
            <div className="section-title position-relative pb-3 mb-5">
              <h5 className="fw-bold text-primary text-uppercase">
                Request A Quote
              </h5>
              <h1 className="mb-0">
                Need A Free Quote? Please Feel Free to Contact Us
              </h1>
            </div>
            <div className="row gx-3">
              <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                <h5 className="mb-4">
                  <i className="fa fa-reply text-primary me-3"></i>Reply within
                  24 hours
                </h5>
              </div>
              <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                <h5 className="mb-4">
                  <i className="fa fa-phone-alt text-primary me-3"></i>24 hrs
                  telephone support
                </h5>
              </div>
            </div>
            <p className="mb-4">
              Please feel free to reach out to us for a complimentary quote on
              our services. Our team is eager to assist you and provide
              customized solutions to meet your needs. Contact us today to get
              started!
            </p>
            <div
              className="d-flex align-items-center mt-2 wow zoomIn"
              data-wow-delay="0.6s"
            >
              <div
                className="bg-primary d-flex align-items-center justify-content-center rounded"
                style={{ width: "60px", height: "60px" }}
              >
                <i className="fa fa-phone-alt text-white"></i>
              </div>
              <div className="ps-4">
                <h5 className="mb-2">Call to ask any question</h5>
                <h4 className="text-primary mb-0">+91 722-388-8881</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div
              className="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn"
              data-wow-delay="0.9s"
            >
              <form onSubmit={handleSubmit}>
                <div className="row g-3">
                  <div className="col-xl-12">
                    <input
                      type="text"
                      className="form-control bg-light border-0"
                      placeholder="Your Name"
                      style={{ height: "55px" }}
                      name="name"
                      value={userData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="email"
                      className="form-control bg-light border-0"
                      placeholder="Your Email"
                      style={{ height: "55px" }}
                      name="email"
                      value={userData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12">
                    <select
                      className="form-select bg-light border-0"
                      style={{ height: "55px" }}
                      name="service"
                      value={userData.service}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled>
                        Select A Service
                      </option>
                      <option value="Custom PHP Development Services">
                        Custom PHP Development Services
                      </option>
                      <option value="React.js Development Service">
                        React.js Development Service
                      </option>
                      <option value="React Native App Development Service">
                        React Native App Development Service
                      </option>
                      <option value="Ios App Development Services">
                        Ios App Development Services
                      </option>
                      <option value="Full-Stack Development Services">
                        Full-Stack Development Services
                      </option>
                      <option value="PHP and React.js Migration">
                        PHP and React.js Migration
                      </option>
                      <option value="Custom API Development">
                        Custom API Development
                      </option>
                      <option value="Maintenance and Support">
                        Maintenance and Support
                      </option>
                    </select>
                  </div>
                  <div className="col-12">
                    <textarea
                      className="form-control bg-light border-0"
                      rows="3"
                      placeholder="Message"
                      name="message"
                      value={userData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <button className="btn btn-dark w-100 py-3" type="submit">
                      Request A Quote
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default RequestQuote;
