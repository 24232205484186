import React, { useState } from "react";
import image1 from "./../../assets/jtPics/jt-1.jpg";
import image2 from "../../assets/jtPics/jt-2.jpg";
import image3 from "../../assets/jtPics/jt-3.jpg";
import image4 from "../../assets/jtPics/jt-4.jpg";
import image5 from "../../assets/jtPics/jt-5.jpg";
import image6 from "../../assets/jtPics/jt-6.jpg";
import image7 from "../../assets/jtPics/jt-7.jpg";
import image8 from "../../assets/jtPics/jt-8.jpg";
import image9 from "../../assets/jtPics/jt-9.jpg";
import image10 from "../../assets/jtPics/jt-10.jpg";
import image11 from "../../assets/jtPics/jt-11.jpg";
import image12 from "../../assets/jtPics/jt-12.jpg";
import image13 from "../../assets/jtPics/jt-13.jpg";
import image14 from "../../assets/jtPics/jt-14.jpg";
import image15 from "../../assets/jtPics/jt-15.jpg";
import image16 from "../../assets/jtPics/jt-16.jpg";
import image17 from "../../assets/jtPics/jt-17.jpg";
import image18 from "../../assets/jtPics/jt-18.jpg";
import image19 from "../../assets/jtPics/jt-19.jpg";
import image20 from "../../assets/jtPics/jt-20.jpg";
import SliderImage from "../../components/Slider";
import { IoClose } from "react-icons/io5";
import { Dialog } from "@mui/material";
import SliderPort from "../../components/SliderPort";
const ERPPortfolio = () => {
  const [open, setOpen] = useState(false);
  const images = [
    {
      id: 1,
      image: image1,
    },
    {
      id: 2,
      image: image2,
    },
    {
      id: 3,
      image: image3,
    },
    {
      id: 4,
      image: image4,
    },
    {
      id: 5,
      image: image5,
    },
    {
      id: 6,
      image: image6,
    },
    {
      id: 7,
      image: image7,
    },
    {
      id: 8,
      image: image8,
    },
    {
      id: 9,
      image: image9,
    },
    {
      id: 10,
      image: image10,
    },
    {
      id: 11,
      image: image11,
    },
    {
      id: 12,
      image: image12,
    },
    {
      id: 13,
      image: image13,
    },
    {
      id: 14,
      image: image14,
    },
    {
      id: 15,
      image: image15,
    },
    {
      id: 16,
      image: image16,
    },
    {
      id: 17,
      image: image17,
    },
    {
      id: 18,
      image: image18,
    },
    {
      id: 19,
      image: image19,
    },
    {
      id: 20,
      image: image20,
    },
  ];
  return (
    <div>
      <div
        className="container mt-4 boxShadow"
        style={{
          backgroundColor: "#F0F8FA",
          padding: "30px",
          boxShadow:
            "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
        }}
      >
        <div className="row">
          <div className="col-md-6">
            <h2 className="">Julie Tailors ERP </h2>{" "}
            <div className="d-flex mt-1 gap-3  shadow-lg p-4">
              <div className="  info-list-div d-flex flex-column gap-1 py-2">
                <div>Admin & Multi Branch Panel</div>
                <div>Vendor Management</div>{" "}
                <div>
                  Account Ledger Management
                  <br />
                  <span style={{ fontSize: "13px" }}>
                    (Cash Account ledger , Bank Account Ledger , PayTm Account
                    Ledger , Shop Expanse Account Ledger , Employee Account
                    Ledger , Vendor Account Ledger etc. )
                  </span>
                </div>{" "}
                <div>
                  Order Management <br />
                  <span style={{ fontSize: "13px" }}>
                    {" "}
                    (Direct , Counter & Cosmetic orders)
                  </span>
                </div>{" "}
                <div>Sales Management</div>
                <div>
                  Employee Management <br />
                  <span style={{ fontSize: "13px" }}>
                    (Account , Salary , Attendance etc.)
                  </span>
                </div>
                <div>Order Billing</div>
                <div>
                  Employee Work Allocation & Submission Management <br />
                  <span style={{ fontSize: "13px" }}>
                    (Cloth Cutting & Stitching)
                  </span>
                </div>{" "}
                <div>Raw & Readymade Stock Inventory Management</div>{" "}
              </div>{" "}
            </div>{" "}
          </div>
          <div className="col-md-6">
            {/* Second column with slider */}
            <SliderImage images={images} setOpen={setOpen} height={"475px"} />
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        // fullWidth
        onClose={() => setOpen(false)}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1200px",
            },
          },
        }}
      >
        <div className="p-4">
          <div className="d-flex align-items-center justify-content-between px-2">
            <h2
              className="text-center textFamily text-primary"
              style={{ fontSize: "28px" }}
            >
              Julie Tailors ERP
            </h2>
            <div
              style={{
                fontSize: "25px",
                marginBottom: "10px",
                cursor: "pointer",
              }}
              onClick={() => setOpen(false)}
            >
              <IoClose />
            </div>
          </div>{" "}
          <div className="d-none d-sm-block">
            <SliderPort images={images} height={"630px"} />
          </div>
          <div style={{ width: "100%" }} className="d-sm-none">
            {images.map((el) => {
              return (
                <div>
                  <img
                    src={el?.image}
                    alt=""
                    style={{
                      height: "180px",
                      objectFit: "contain",
                      width: "100%",
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ERPPortfolio;
